<template>
    <b-modal
        id="modal-prefijo"
        header-bg-variant="primary"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5 class="text-white">Seleccione un prefijo</h5>
        </template>
        <b-card class="p-3">
            <b-list-group v-if="consecutivos.length > 0">
                <b-list-group-item
                    v-for="(consecutivo, index) in consecutivos"
                    :key="index"
                    @click="setSelectedPrefijo(consecutivo)"
                    class="d-flex justify-content-between align-items-center"
                >
                    {{ consecutivo.prefijo }}
                    <feather
                        v-b-popover.hover.top="'No seleccionado'"
                        v-if="selectedPrefijo !== consecutivo.id"
                        type="circle"
                        role="button"
                        class="mr-2 text-secondary"
                    ></feather>
                    <feather
                        v-else
                        v-b-popover.hover.top="'Prefijo seleccionado'"
                        type="disc"
                        role="button"
                        class="mr-2 text-success"
                    ></feather>
                </b-list-group-item>
            </b-list-group>
            <div v-else class="text-center">
                <span>No hay consecutivos creados</span>
                <div
                    class="d-flex align-items-center justify-content-center mt-4"
                >
                    <b-button
                        variant="primary"
                        size="md"
                        class="d-flex align-items-center"
                        @click="
                            $router.push(
                                '/home/administrativo/configuracion/consecutivos/crear-consecutivo'
                            )
                        "
                    >
                        <feather
                            type="plus"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span>Crear consecutivo</span>
                    </b-button>
                </div>
            </div>
        </b-card>
    </b-modal>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'PrefijoModal',
    data: () => ({
        selectedPrefijo: null
    }),
    props: { consecutivos: { type: Array, default: () => [] } },
    methods: {
        setSelectedPrefijo(consecutivo) {
            this.selectedPrefijo = consecutivo.id
            this.$emit('selectedConsecutivo', consecutivo)
            this.close()
        },
        close() {
            this.$bvModal.hide('modal-prefijo')
        }
    },
    computed: {
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
