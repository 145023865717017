<template>
    <b-modal
        id="modal-detalle-factura"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Detalle de retenciones"
    >
        <b-card class="p-3">
            <label for="table_retencion_details" class="font-weight-bold"
                >Detalles retefuente</label
            >
            <b-table
                id="table_retencion_details"
                responsive
                :items="details.retencion"
                :fields="fields_retencion"
            ></b-table>

            <label for="table_reteiva_details" class="font-weight-bold mt-4"
                >Detalles reteiva</label
            >
            <b-table
                id="table_retencion_details"
                responsive
                :items="details.reteiva"
                :fields="fields_reteiva"
            ></b-table>
        </b-card>
    </b-modal>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'DetalleFactura',
    data: () => ({
        fields_retencion: [
            { key: 'group', label: 'Porcentaje' },
            { key: 'base_retencion', label: 'Base de retención' },
            { key: 'value', label: 'Valor' }
        ],
        fields_reteiva: [
            { key: 'group', label: 'Porcentaje' },
            { key: 'value', label: 'Valor' }
        ]
    }),
    props: { details: { type: Object, default: () => ({}) } },
    methods: {
        close() {
            this.$bvModal.hide('modal-detalle-factura')
        }
    },
    computed: {
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
