var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center mb-4"},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"arrow-left-circle","role":"button","size":"2rem"},on:{"click":_vm.back}}),_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)],1)]),_c('b-col',{staticClass:"d-flex align-items-stretch",staticStyle:{"min-height":"70vh"},attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100"},[_c('div',[_c('b-form',{staticClass:"p-5"},[_c('b-form',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Número documento")])]),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('b-form-input',{attrs:{"id":"prefijo_devolucion","disabled":"","state":_vm.validateStateDevolucion(
                                            'consecutivo'
                                        )},model:{value:(
                                        _vm.$v.devolucion_factura.consecutivo
                                            .prefijo.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.devolucion_factura.consecutivo
                                            .prefijo, "$model", $$v)},expression:"\n                                        $v.devolucion_factura.consecutivo\n                                            .prefijo.$model\n                                    "}}),(
                                        !_vm.$v.devolucion_factura.consecutivo
                                            .required &&
                                        _vm.$v.devolucion_factura.consecutivo
                                            .$error
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"numero_devolucion","type":"number","state":_vm.validateStateDevolucion('numero'),"disabled":""},model:{value:(_vm.devolucion_factura.numero),callback:function ($$v) {_vm.$set(_vm.devolucion_factura, "numero", $$v)},expression:"devolucion_factura.numero"}}),(
                                        !_vm.$v.devolucion_factura.numero
                                            .required &&
                                        _vm.$v.devolucion_factura.numero.$dirty
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"3"}},[_c('label',{attrs:{"for":"factura_proveedor_id"}},[_vm._v("No. Compra")]),_c('jautocomplete',{attrs:{"id":"factura_proveedor_id","items":_vm.compras,"item-text":"descripcion","item-value":"id","load-async":"","open-on-focus":"","placeholder":"Número o prefijo de la compra","custom-text-field":_vm.textFieldFactura,"disabled":_vm.read_only,"no-null":"","v-model":_vm.$v.devolucion_factura
                                            .factura_proveedor_id.$model},on:{"text":_vm.filterFacturas,"change":_vm.getCompraWithDevoluciones},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.consecutivo.prefijo)+" - "+_vm._s(item.numero))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"consecutivo_proveedor"}},[_vm._v("Factura proveedor")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"consecutivo_proveedor","type":"text","placeholder":"EJ: A12","disabled":_vm.read_only ||
                                            _vm.devolucion_factura.factura_proveedor_id !==
                                                null,"autocomplete":"null"},model:{value:(
                                            _vm.$v.devolucion_factura
                                                .consecutivo_proveedor
                                                .$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.devolucion_factura
                                                .consecutivo_proveedor
                                                , "$model", $$v)},expression:"\n                                            $v.devolucion_factura\n                                                .consecutivo_proveedor\n                                                .$model\n                                        "}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"proveedor_id"}},[_vm._v("Proveedor")]),_c('jautocomplete',{ref:"proveedor_id",attrs:{"id":"proveedor_id","items":_vm.proveedores,"item-text":"nombre1","item-value":"id","load-async":"","open-on-focus":"","state":_vm.validateState('proveedor_id'),"disabled":_vm.read_only ||
                                        _vm.devolucion_factura.factura_proveedor_id !==
                                            null,"show-create-button":"","create-method":() => {
                                            _vm.$bvModal.show(
                                                'create-establecimiento-comercio-modal'
                                            )
                                        }},on:{"text":_vm.filterCliente,"change":_vm.assignProveedor},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.getLabelCliente(item)))])]}}]),model:{value:(
                                        _vm.$v.factura_venta.proveedor_id.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.proveedor_id, "$model", $$v)},expression:"\n                                        $v.factura_venta.proveedor_id.$model\n                                    "}}),(
                                        _vm.$v.factura_venta.proveedor_id.$error
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"fecha"}},[_vm._v("Fecha")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"fecha","type":"text","placeholder":"AAAA-MM-DD","state":_vm.validateState('fecha_factura'),"disabled":"","autocomplete":"null"},on:{"change":_vm.onChangeFechaFactura},model:{value:(
                                            _vm.$v.devolucion_factura.fecha
                                                .$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.devolucion_factura.fecha
                                                , "$model", $$v)},expression:"\n                                            $v.devolucion_factura.fecha\n                                                .$model\n                                        "}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","locale":"en-US","state":_vm.validateStateDevolucion(
                                                    'fecha'
                                                ),"disabled":"","aria-controls":"fecha"},on:{"input":_vm.onChangeFechaFactura},model:{value:(
                                                _vm.$v.devolucion_factura.fecha
                                                    .$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.devolucion_factura.fecha
                                                    , "$model", $$v)},expression:"\n                                                $v.devolucion_factura.fecha\n                                                    .$model\n                                            "}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.devolucion_factura.fecha
                                            .formatDate}},[_vm._v(" Formato de fecha inválido ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.devolucion_factura.fecha
                                            .compareDates}},[_vm._v(" La fecha no puede ser mayor al vencimiento ")])],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"plazo"}},[_vm._v("Plazo (días)")]),_c('b-form-input',{attrs:{"id":"plazo","disabled":_vm.read_only,"type":"number","state":_vm.validateStateSingle(
                                            'plazo_vencimiento'
                                        ),"autocomplete":"null"},on:{"input":_vm.assignVencimiento},model:{value:(_vm.$v.plazo_vencimiento.$model),callback:function ($$v) {_vm.$set(_vm.$v.plazo_vencimiento, "$model", $$v)},expression:"$v.plazo_vencimiento.$model"}}),(
                                        !_vm.$v.plazo_vencimiento
                                            .noNegativeNumbers
                                    )?_c('b-form-invalid-feedback',[_vm._v(" El plazo no puede ser negativo ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"vencimiento"}},[_vm._v("Vencimiento")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vencimiento","type":"text","placeholder":"AAAA-MM-DD","disabled":"","autocomplete":"null"},model:{value:(
                                            _vm.$v.devolucion_factura
                                                .vencimiento.$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.devolucion_factura
                                                .vencimiento, "$model", $$v)},expression:"\n                                            $v.devolucion_factura\n                                                .vencimiento.$model\n                                        "}})],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.devolucion_factura.vencimiento
                                            .formatDate}},[_vm._v(" Formato de fecha inválido ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.devolucion_factura.vencimiento
                                            .compareDates}},[_vm._v(" El vencimiento de la factura no puede ser mayor a la fecha ")])],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('label',{attrs:{"for":"tipo_devolucion_id"}},[_vm._v("Tipo devolución")]),_c('jautocomplete',{attrs:{"id":"tipo_devolucion_id","items":_vm.tipos_devoluciones,"item-text":"descripcion","item-value":"id","open-on-focus":"","disabled":_vm.read_only,"state":_vm.validateStateDevolucion(
                                            'tipo_devolucion_id'
                                        )},model:{value:(
                                        _vm.$v.devolucion_factura
                                            .tipo_devolucion_id.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.devolucion_factura
                                            .tipo_devolucion_id, "$model", $$v)},expression:"\n                                        $v.devolucion_factura\n                                            .tipo_devolucion_id.$model\n                                    "}}),(
                                        !_vm.$v.devolucion_factura
                                            .tipo_devolucion_id.required &&
                                        _vm.$v.devolucion_factura
                                            .tipo_devolucion_id.$error
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"nota"}},[_vm._v("Nota")]),_c('b-input-group',[_c('b-form-textarea',{attrs:{"id":"nota","size":"sm","disabled":_vm.read_only,"rows":"1","placeholder":"Alguna anotación?..."},model:{value:(
                                            _vm.$v.devolucion_factura.nota
                                                .$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.devolucion_factura.nota
                                                , "$model", $$v)},expression:"\n                                            $v.devolucion_factura.nota\n                                                .$model\n                                        "}})],1),(
                                        !_vm.$v.devolucion_factura.nota
                                            .$maxLength
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.devolucion_factura.nota.$model .length)+" / "+_vm._s(_vm.$v.devolucion_factura.nota.$params .maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.devolucion_factura.nota
                                            .maxLength
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.devolucion_factura.nota.$params .maxLength.max)+" caracteres ")]):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-block d-sm-flex justify-content-between mb-5"},[_c('label',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Productos")]),_c('div',[_c('b-button',{staticClass:"d-flex align-items-center mt-4 mt-sm-0",attrs:{"variant":"primary","size":"md"},on:{"click":function($event){return _vm.$bvModal.show(
                                                    'modal-detalle-factura'
                                                )}}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"eye","size":"1rem"}}),_vm._v(" Detalles de retenciones ")],1),_c('detalleFacturaVue',{attrs:{"details":_vm.detalle_factura}})],1)]),_c('b-row',{staticClass:"my-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                'ctrl+b ó cmd+b'
                                            ),expression:"\n                                                'ctrl+b ó cmd+b'\n                                            ",modifiers:{"hover":true,"top":true}}],ref:"itemsButton",staticClass:"d-flex align-items-center mt-4 mt-sm-0",attrs:{"variant":"primary","size":"md","disabled":_vm.read_only || _vm.$v.devolucion_factura.factura_proveedor_id.$model !== null},on:{"click":function($event){return _vm.$bvModal.show('modal-items')}}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"search","size":"1rem"}}),_vm._v(" Más items ")],1)],1)],1),_c('div',{staticClass:"mt-4 mb-4"},[(
                                            _vm.devolucion_factura.factura_proveedor_id
                                                ? _vm.factura_venta.productos
                                                      .length >= 2
                                                : _vm.factura_venta.productos
                                                      .length >= 3
                                        )?_c('b-input-group',[_c('b-form-input',{staticClass:"bg-background-input",attrs:{"id":"filter-input","type":"text","placeholder":"Filtrar de la lista de productos"},on:{"input":function($event){return _vm.searchData()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"text-blue-active d-flex align-items-center",attrs:{"variant":"background-input"},on:{"click":function($event){return _vm.searchData()}}},[_c('feather',{attrs:{"type":"search","size":"1.1rem"}})],1)],1)],1):_vm._e()],1),_c('b-table',{attrs:{"small":"","hover":"","responsive":"","foot-clone":"","striped":"","items":_vm.factura_venta_productosCopy,"fields":_vm.titulosProductos},scopedSlots:_vm._u([{key:"cell(referencia)",fn:function(data){return [(!data.item.item_id)?_c('jautocomplete',{attrs:{"id":`referencia-${data.item.item_id}`,"items":_vm.productos,"item-text":"referencia","item-value":"id","return-object":"","load-async":"","open-on-focus":"","disabled":_vm.read_only,"show-create-button":"","create-method":() => {
                                                    _vm.$bvModal.show(
                                                        'create-item-modal'
                                                    )
                                                }},on:{"text":_vm.filterProductos,"change":_vm.insertProducto}}):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(descripcion)",fn:function(data){return [(!data.item.item_id)?_c('jautocomplete',{attrs:{"id":`producto-${data.item.item_id}`,"items":_vm.productos,"item-text":"descripcion","item-value":"id","return-object":"","load-async":"","open-on-focus":"","disabled":_vm.read_only,"show-create-button":"","create-method":() => {
                                                    _vm.$bvModal.show(
                                                        'create-item-modal'
                                                    )
                                                }},on:{"text":_vm.filterProductos,"change":_vm.insertProducto},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.getLabelItem(item)))])]}}],null,true)}):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(valor)",fn:function(data){return [(
                                                _vm.devolucion_factura.factura_proveedor_id ===
                                                    null &&
                                                data.item.item_id !== null
                                            )?_c('currency-input',{attrs:{"id":`valor-producto-${data.item.item_id}`,"disabled":_vm.read_only ||
                                                !data.item.item_id,"state":_vm.validateStateProducts(
                                                    data.index,
                                                    'valor'
                                                )},on:{"input":() => {
                                                    _vm.calcTotalIva(data)
                                                    _vm.calcDescuento(data)
                                                },"focus":_vm.onFocused},model:{value:(
                                                _vm.$v.factura_venta.productos
                                                    .$each[data.index].valor
                                                    .$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.productos
                                                    .$each[data.index].valor
                                                    , "$model", _vm._n($$v))},expression:"\n                                                $v.factura_venta.productos\n                                                    .$each[data.index].valor\n                                                    .$model\n                                            "}}):_c('span',[_vm._v(_vm._s(_vm.currencyFormat( _vm.$v .factura_venta_productosCopy .$each[data.index].valor .$model )))])]}},{key:"cell(cantidad)",fn:function(data){return [(data.item.item_id)?_c('span',[_c('b-form-input',{attrs:{"id":`cantidad-producto-${data.item.item_id}`,"disabled":_vm.read_only ||
                                                    !data.item.item_id,"type":"number","state":_vm.validateStateProducts(
                                                        data.index,
                                                        'cantidad'
                                                    ),"autocomplete":"null"},on:{"focus":_vm.onFocused,"change":function($event){return _vm.getExistenciaProducto(
                                                        data
                                                    )}},model:{value:(
                                                    _vm.$v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .cantidad.$model
                                                ),callback:function ($$v) {_vm.$set(_vm.$v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .cantidad, "$model", $$v)},expression:"\n                                                    $v\n                                                        .factura_venta_productosCopy\n                                                        .$each[data.index]\n                                                        .cantidad.$model\n                                                "}}),(
                                                    !_vm.$v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .cantidad.required
                                                )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e(),(
                                                    !_vm.$v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .cantidad.maxLength
                                                )?_c('b-form-invalid-feedback',[_vm._v(" Máximo "+_vm._s(_vm.$v .factura_venta_productosCopy .$each[data.index] .cantidad.$params .maxLength.max)+" caracteres ")]):_vm._e()],1):_c('span',[_vm._v(_vm._s(_vm.$v.factura_venta_productosCopy .$each[data.index].cantidad .$model))])]}},{key:"cell(porcentaje_descuento)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.$v.factura_venta_productosCopy .$each[data.index] .porcentaje_descuento.$model))])]}},{key:"cell(total_iva)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.tarifa_iva.porcentaje)+"% ")]}},{key:"cell(total_impoconsumo)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcTotalImpoconsumo(data).toFixed(2) ))+" ")]}},{key:"cell(total)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.calcTotal(data).toFixed(2)))+" ")]}},{key:"cell(acciones)",fn:function(row){return [(
                                                _vm.read_only ||
                                                !row.item.item_id ||
                                                _vm.devolucion_factura.factura_proveedor_id
                                            )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                'Eliminar producto'
                                            ),expression:"\n                                                'Eliminar producto'\n                                            ",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"trash-2","size":"1.3rem"}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                'Eliminar producto'
                                            ),expression:"\n                                                'Eliminar producto'\n                                            ",modifiers:{"hover":true,"top":true}}],staticClass:"text-error",attrs:{"type":"trash-2","size":"1.3rem","role":"button"},on:{"click":function($event){return _vm.confirmRemoveItem(row)}}}),_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                row.detailsShowing
                                                    ? 'Ocultar detalles'
                                                    : 'Ver detalles'
                                            ),expression:"\n                                                row.detailsShowing\n                                                    ? 'Ocultar detalles'\n                                                    : 'Ver detalles'\n                                            ",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary ml-2",attrs:{"type":row.detailsShowing
                                                    ? 'arrow-up-circle'
                                                    : 'arrow-down-circle',"size":"1.3rem","role":"button"},on:{"click":($evt) => {
                                                    row.toggleDetails($evt)
                                                    _vm.setBodegaAndLote(
                                                        row.item
                                                    )
                                                }}})]}},{key:"foot()",fn:function(data){return [(
                                                data.field.key ===
                                                'referencia'
                                            )?_c('div',[_c('b',[_vm._v("Subtotal")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.subtotal_neto.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'descripcion'
                                            )?_c('div',[_c('b',[_vm._v("Descuento")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.descuento.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'porcentaje_descuento'
                                            )?_c('div',[_c('b',[_vm._v("Iva")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.total_iva.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'cantidad'
                                            )?_c('div',[_c('b',[_vm._v("Impoconsumo")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.total_ipoconsumo.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key === 'valor'
                                            )?_c('div',[_c('b',[_vm._v("Retefuente")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.total_retefuente.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'acciones'
                                            )?_c('div',[_c('b',[_vm._v("Total")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.granTotal.toFixed(2) ))+" ")])]):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-row',{staticClass:"pa-2"},[_c('b-col',{attrs:{"cols":"2"}},[_c('b',[_vm._v("Descuento")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( row.item .descuento.toFixed(2) ))+" ")])]),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"bodega_id"}},[_vm._v("Bodega")]),_c('jautocomplete',{attrs:{"id":"bodega_id","items":_vm.bodegas,"item-text":"descripcion","item-value":"id","open-on-focus":"","disabled":_vm.read_only ||
                                                            !row.item
                                                                .item_id ||
                                                            _vm.$v
                                                                .devolucion_factura
                                                                .factura_proveedor_id
                                                                .$model !==
                                                                null},on:{"change":function($event){return _vm.getExistenciaProducto(
                                                                row
                                                            )}},model:{value:(
                                                            row.item
                                                                .bodega_id
                                                        ),callback:function ($$v) {_vm.$set(row.item
                                                                , "bodega_id", $$v)},expression:"\n                                                            row.item\n                                                                .bodega_id\n                                                        "}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"lote_inventario_id"}},[_vm._v("Lote de inventario")]),_c('jautocomplete',{attrs:{"id":"lote_inventario_id","items":_vm.lotes_inventario,"item-text":"nombre","item-value":"id","open-on-focus":"","disabled":_vm.read_only ||
                                                            !row.item
                                                                .item_id ||
                                                            _vm.$v
                                                                .devolucion_factura
                                                                .factura_proveedor_id
                                                                .$model !==
                                                                null},on:{"change":function($event){return _vm.getExistenciaProducto(
                                                                row
                                                            )}},model:{value:(
                                                            row.item
                                                                .lote_inventario_id
                                                        ),callback:function ($$v) {_vm.$set(row.item
                                                                , "lote_inventario_id", $$v)},expression:"\n                                                            row.item\n                                                                .lote_inventario_id\n                                                        "}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"tercero_id"}},[_vm._v("Tercero")]),_c('jautocomplete',{attrs:{"id":"tercero_id","items":_vm.tercerosCopy,"item-value":"id","item-text":"n_documento","custom-filter":_vm.filterTerceros,"open-on-focus":"","custom-text-field":_vm.textFieldTercero,"show-create-button":"","disabled":_vm.read_only ||
                                                            !row.item.item_id ||
                                                            !_vm.permisos.includes('devolucion_proveedor-modificar-tercero')},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('div',{staticClass:"p-1 option",attrs:{"role":"button"}},[_vm._v(" "+_vm._s(item.tipo_documento_id === '31' ? item.razon_social : `${item.nombre1} ${item.apellido1}`)+" ")])]}}],null,true),model:{value:(row.item.tercero_id),callback:function ($$v) {_vm.$set(row.item, "tercero_id", $$v)},expression:"row.item.tercero_id"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"concepto_contable_id"}},[_vm._v("Concepto contable")]),_c('jautocomplete',{attrs:{"id":"concepto_contable_id","items":_vm.conceptos_contables,"item-text":"descripcion","item-value":"id","open-on-focus":"","disabled":_vm.read_only ||
                                                            !row.item
                                                                .item_id || !_vm.permisos.includes('devolucion_proveedor-modificar-concepto-contable')},model:{value:(row.item
                                                            .concepto_contable_id
                                                            ),callback:function ($$v) {_vm.$set(row.item
                                                            , "concepto_contable_id", $$v)},expression:"row.item\n                                                            .concepto_contable_id\n                                                            "}})],1)],1)],1)]}}])}),(_vm.productsLengthError)?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" La lista de productos debe tener al menos "+_vm._s(_vm.$v.factura_venta.productos.$params .minLength.min)+" producto ")]):_vm._e()],1)],1)],1)],1),_c('prefijoModal',{attrs:{"consecutivos":_vm.consecutivos},on:{"selectedConsecutivo":_vm.setConsecutivo}})],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)])]),_c('crearEstablecimientoComercioModalVue',{on:{"newEstablecimientoComercio":_vm.setNewEstablecimientoComercio}}),_c('crearItemVue',{on:{"newItem":_vm.setNewItem}}),_c('modalItem',{on:{"selectedItem":_vm.insertProducto}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }