<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <b-form v-hotkey="keymap">
                            <!-- <div
                                class="d-flex justify-content-between align-items-center mb-5"
                            > -->
                            <!-- <b-col cols="12"> -->
                            <!-- <div class="font-weight-bold mb-5">
                                Datos de la devolución
                            </div> -->
                            <!-- </div> -->
                            <b-row class="mb-5">
                                <b-col cols="12" sm="6">
                                    <label>Número documento</label>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <!-- <label for="prefijo_devolucion"
                                                >Prefijo</label
                                            > -->
                                    <b-form-input
                                        id="prefijo_devolucion"
                                        disabled
                                        v-model="
                                            $v.devolucion_factura.consecutivo
                                                .prefijo.$model
                                        "
                                        :state="
                                            validateStateDevolucion(
                                                'consecutivo'
                                            )
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.consecutivo
                                                .required &&
                                            $v.devolucion_factura.consecutivo
                                                .$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <!-- <label for="numero_devolucion"
                                                >Número</label
                                            > -->
                                    <b-form-input
                                        id="numero_devolucion"
                                        type="number"
                                        class="form-control"
                                        :state="
                                            validateStateDevolucion('numero')
                                        "
                                        disabled
                                        v-model="devolucion_factura.numero"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.numero
                                                .required &&
                                            $v.devolucion_factura.numero.$dirty
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" sm="3" class="mb-4">
                                    <label for="factura_proveedor_id"
                                        >No. Compra</label
                                    >
                                    <jautocomplete
                                        id="factura_proveedor_id"
                                        :items="compras"
                                        item-text="descripcion"
                                        item-value="id"
                                        load-async
                                        open-on-focus
                                        placeholder="Número o prefijo de la compra"
                                        :custom-text-field="textFieldFactura"
                                        :disabled="read_only"
                                        @text="filterFacturas"
                                        @change="getCompraWithDevoluciones"
                                        no-null
                                        :v-model="
                                            $v.devolucion_factura
                                                .factura_proveedor_id.$model
                                        "
                                    >
                                        <template v-slot:option="{ item }">
                                            <span>
                                                {{ item.consecutivo.prefijo }} -
                                                {{ item.numero }}</span
                                            >
                                        </template>
                                    </jautocomplete>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <label for="consecutivo_proveedor"
                                        >Factura proveedor</label
                                    >
                                    <b-input-group>
                                        <b-form-input
                                            id="consecutivo_proveedor"
                                            v-model="
                                                $v.devolucion_factura
                                                    .consecutivo_proveedor
                                                    .$model
                                            "
                                            type="text"
                                            placeholder="EJ: A12"
                                            :disabled="
                                                read_only ||
                                                devolucion_factura.factura_proveedor_id !==
                                                    null
                                            "
                                            autocomplete="null"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <label for="proveedor_id">Proveedor</label>
                                    <jautocomplete
                                        id="proveedor_id"
                                        ref="proveedor_id"
                                        :items="proveedores"
                                        item-text="nombre1"
                                        item-value="id"
                                        load-async
                                        open-on-focus
                                        @text="filterCliente"
                                        @change="assignProveedor"
                                        :state="validateState('proveedor_id')"
                                        :disabled="
                                            read_only ||
                                            devolucion_factura.factura_proveedor_id !==
                                                null
                                        "
                                        v-model="
                                            $v.factura_venta.proveedor_id.$model
                                        "
                                        show-create-button
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-establecimiento-comercio-modal'
                                                )
                                            }
                                        "
                                    >
                                        <template v-slot:option="{ item }">
                                            <span>
                                                {{
                                                    getLabelCliente(item)
                                                }}</span
                                            >
                                        </template>
                                    </jautocomplete>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.factura_venta.proveedor_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col cols="12" md="3">
                                    <label for="fecha">Fecha</label>
                                    <b-input-group>
                                        <b-form-input
                                            id="fecha"
                                            v-model="
                                                $v.devolucion_factura.fecha
                                                    .$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            :state="
                                                validateState('fecha_factura')
                                            "
                                            disabled
                                            autocomplete="null"
                                            @change="onChangeFechaFactura"
                                        ></b-form-input>
                                        <!-- @change="showLog" -->
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                v-model="
                                                    $v.devolucion_factura.fecha
                                                        .$model
                                                "
                                                button-only
                                                right
                                                locale="en-US"
                                                :state="
                                                    validateStateDevolucion(
                                                        'fecha'
                                                    )
                                                "
                                                disabled
                                                @input="onChangeFechaFactura"
                                                aria-controls="fecha"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.fecha
                                                .formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.fecha
                                                .compareDates
                                        "
                                    >
                                        La fecha no puede ser mayor al
                                        vencimiento
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <label for="plazo">Plazo (días)</label>
                                    <b-form-input
                                        id="plazo"
                                        v-model="$v.plazo_vencimiento.$model"
                                        :disabled="read_only"
                                        @input="assignVencimiento"
                                        type="number"
                                        :state="
                                            validateStateSingle(
                                                'plazo_vencimiento'
                                            )
                                        "
                                        autocomplete="null"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.plazo_vencimiento
                                                .noNegativeNumbers
                                        "
                                    >
                                        El plazo no puede ser negativo
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <label for="vencimiento">Vencimiento</label>
                                    <b-input-group>
                                        <b-form-input
                                            id="vencimiento"
                                            v-model="
                                                $v.devolucion_factura
                                                    .vencimiento.$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            disabled
                                            autocomplete="null"
                                        ></b-form-input>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.vencimiento
                                                .formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.devolucion_factura.vencimiento
                                                .compareDates
                                        "
                                    >
                                        El vencimiento de la factura no puede
                                        ser mayor a la fecha
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <label for="tipo_devolucion_id"
                                        >Tipo devolución</label
                                    >
                                    <jautocomplete
                                        id="tipo_devolucion_id"
                                        :items="tipos_devoluciones"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="read_only"
                                        v-model="
                                            $v.devolucion_factura
                                                .tipo_devolucion_id.$model
                                        "
                                        :state="
                                            validateStateDevolucion(
                                                'tipo_devolucion_id'
                                            )
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura
                                                .tipo_devolucion_id.required &&
                                            $v.devolucion_factura
                                                .tipo_devolucion_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                                <b-col cols="12" class="mt-5">
                                    <label for="nota">Nota</label>
                                    <b-input-group>
                                        <b-form-textarea
                                            id="nota"
                                            size="sm"
                                            :disabled="read_only"
                                            v-model="
                                                $v.devolucion_factura.nota
                                                    .$model
                                            "
                                            rows="1"
                                            placeholder="Alguna anotación?..."
                                        ></b-form-textarea>
                                    </b-input-group>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.nota
                                                .$maxLength
                                        "
                                    >
                                        ({{
                                            $v.devolucion_factura.nota.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.devolucion_factura.nota.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.devolucion_factura.nota
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.devolucion_factura.nota.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </div>
                                </b-col>
                            </b-row>
                            <!-- <b-row class="mt-4">
                                <b-col cols="12">
                                    <label for="nota">Nota</label>
                                    <b-input-group>
                                        <b-form-textarea
                                            id="nota"
                                            size="sm"
                                            :disabled="read_only"
                                            v-model="
                                                $v.factura_venta.nota_factura
                                                    .$model
                                            "
                                            rows="1"
                                            placeholder="Alguna anotación?..."
                                        ></b-form-textarea>
                                    </b-input-group>
                                </b-col>
                            </b-row> -->
                            <b-row class="mt-5">
                                <b-col cols="12">
                                    <div
                                        class="d-block d-sm-flex justify-content-between mb-5"
                                    >
                                        <label class="mb-4 font-weight-bold"
                                            >Productos</label
                                        >
                                        <div>
                                            <b-button
                                                variant="primary"
                                                size="md"
                                                class="d-flex align-items-center mt-4 mt-sm-0"
                                                @click="
                                                    $bvModal.show(
                                                        'modal-detalle-factura'
                                                    )
                                                "
                                            >
                                                <feather
                                                    type="eye"
                                                    size="1rem"
                                                    class="mr-2 text-blue-active"
                                                ></feather>
                                                Detalles de retenciones
                                            </b-button>
                                            <detalleFacturaVue
                                                :details="detalle_factura"
                                            />
                                        </div>
                                    </div>
                                    <b-row class="my-4">
                                        <b-col cols="12">
                                            <b-button
                                                variant="primary"
                                                size="md"
                                                class="d-flex align-items-center mt-4 mt-sm-0"
                                                @click="
                                                    $bvModal.show('modal-items')
                                                "
                                                ref="itemsButton"
                                                v-b-popover.hover.top="
                                                    'ctrl+b ó cmd+b'
                                                "
                                                :disabled="read_only || $v.devolucion_factura.factura_proveedor_id.$model !== null"
                                            >
                                                <feather
                                                    type="search"
                                                    size="1rem"
                                                    class="mr-2 text-blue-active"
                                                ></feather>
                                                Más items
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <div class="mt-4 mb-4">
                                        <b-input-group
                                            v-if="
                                                devolucion_factura.factura_proveedor_id
                                                    ? factura_venta.productos
                                                          .length >= 2
                                                    : factura_venta.productos
                                                          .length >= 3
                                            "
                                        >
                                            <b-form-input
                                                id="filter-input"
                                                type="text"
                                                placeholder="Filtrar de la lista de productos"
                                                class="bg-background-input"
                                                v-model="search"
                                                @input="searchData()"
                                            ></b-form-input>
                                            <!-- @keydown.enter="searchData" -->

                                            <b-input-group-append>
                                                <b-button
                                                    variant="background-input"
                                                    class="text-blue-active d-flex align-items-center"
                                                    @click="searchData()"
                                                >
                                                    <feather
                                                        type="search"
                                                        size="1.1rem"
                                                    ></feather>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                    <b-table
                                        small
                                        hover
                                        responsive
                                        foot-clone
                                        striped
                                        :items="factura_venta_productosCopy"
                                        :fields="titulosProductos"
                                    >
                                        <template #cell(referencia)="data">
                                            <jautocomplete
                                                v-if="!data.item.item_id"
                                                :id="`referencia-${data.item.item_id}`"
                                                :items="productos"
                                                item-text="referencia"
                                                item-value="id"
                                                return-object
                                                load-async
                                                open-on-focus
                                                :disabled="read_only"
                                                @text="filterProductos"
                                                @change="insertProducto"
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        $bvModal.show(
                                                            'create-item-modal'
                                                        )
                                                    }
                                                "
                                            />
                                            <span v-else>{{ data.value }}</span>
                                        </template>
                                        <template #cell(descripcion)="data">
                                            <jautocomplete
                                                v-if="!data.item.item_id"
                                                :id="`producto-${data.item.item_id}`"
                                                :items="productos"
                                                item-text="descripcion"
                                                item-value="id"
                                                return-object
                                                load-async
                                                open-on-focus
                                                :disabled="read_only"
                                                @text="filterProductos"
                                                @change="insertProducto"
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        $bvModal.show(
                                                            'create-item-modal'
                                                        )
                                                    }
                                                "
                                            >
                                                <template
                                                    v-slot:option="{ item }"
                                                >
                                                    <span>
                                                        {{
                                                            getLabelItem(item)
                                                        }}</span
                                                    >
                                                </template>
                                            </jautocomplete>
                                            <span v-else>{{ data.value }}</span>
                                            <!-- <b-form-input
                                                v-if="
                                                    data.item.item_id !==
                                                        null &&
                                                    data.item.item_id !==
                                                        undefined
                                                "
                                                type="text"
                                                v-model="
                                                    $v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .descripcion.$model
                                                "
                                                :id="`producto-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'descripcion'
                                                    )
                                                "
                                            ></b-form-input>
                                            <span v-else>{{ data.value }}</span>
                                            <b-form-invalid-feedback
                                                v-if="
                                                    !$v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index]
                                                        .descripcion.required
                                                "
                                            >
                                                Campo requerido
                                            </b-form-invalid-feedback> -->
                                        </template>
                                        <template #cell(valor)="data">
                                            <currency-input
                                                v-if="
                                                    devolucion_factura.factura_proveedor_id ===
                                                        null &&
                                                    data.item.item_id !== null
                                                "
                                                :id="`valor-producto-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                v-model.number="
                                                    $v.factura_venta.productos
                                                        .$each[data.index].valor
                                                        .$model
                                                "
                                                @input="
                                                    () => {
                                                        calcTotalIva(data)
                                                        calcDescuento(data)
                                                    }
                                                "
                                                @focus="onFocused"
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'valor'
                                                    )
                                                "
                                            >
                                            </currency-input>
                                            <span v-else>{{
                                                currencyFormat(
                                                    $v
                                                        .factura_venta_productosCopy
                                                        .$each[data.index].valor
                                                        .$model
                                                )
                                            }}</span>
                                        </template>
                                        <!-- <template #cell(valor)="data">
                                            {{
                                                currencyFormat(calcValor(data))
                                            }}
                                        </template> -->
                                        <template #cell(cantidad)="data">
                                            <span v-if="data.item.item_id">
                                                <b-form-input
                                                    :id="`cantidad-producto-${data.item.item_id}`"
                                                    :disabled="
                                                        read_only ||
                                                        !data.item.item_id
                                                    "
                                                    @focus="onFocused"
                                                    @change="
                                                        getExistenciaProducto(
                                                            data
                                                        )
                                                    "
                                                    v-model="
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.$model
                                                    "
                                                    type="number"
                                                    :state="
                                                        validateStateProducts(
                                                            data.index,
                                                            'cantidad'
                                                        )
                                                    "
                                                    autocomplete="null"
                                                ></b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.required
                                                    "
                                                >
                                                    Campo requerido
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.maxLength
                                                    "
                                                >
                                                    Máximo
                                                    {{
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad.$params
                                                            .maxLength.max
                                                    }}
                                                    caracteres
                                                </b-form-invalid-feedback>
                                            </span>
                                            <span v-else>{{
                                                $v.factura_venta_productosCopy
                                                    .$each[data.index].cantidad
                                                    .$model
                                            }}</span>
                                        </template>
                                        <!-- <template
                                            #cell(cantidad_devuelta)="data"
                                        >
                                            <span v-if="data.item.item_id">
                                                <b-form-input
                                                    :id="`cantidad-devuelta-producto-${data.item.item_id}`"
                                                    :disabled="
                                                        read_only ||
                                                        !data.item.item_id
                                                    "
                                                    @focus="onFocused"
                                                    v-model="
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .$model
                                                    "
                                                    @input="
                                                        ($evt) => {
                                                            const value =
                                                                Number($evt)
                                                            if (
                                                                value >
                                                                data.item
                                                                    .cantidad
                                                            ) {
                                                                $v.factura_venta_productosCopy.$each[
                                                                    data.index
                                                                ].cantidad_devuelta.$model =
                                                                    data.item.cantidad
                                                            }
                                                        }
                                                    "
                                                    type="number"
                                                    :state="
                                                        validateStateProducts(
                                                            data.index,
                                                            'cantidad_devuelta'
                                                        )
                                                    "
                                                    autocomplete="null"
                                                ></b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .required
                                                    "
                                                >
                                                    Campo requerido
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .maxLength
                                                    "
                                                >
                                                    Máximo
                                                    {{
                                                        $v
                                                            .factura_venta_productosCopy
                                                            .$each[data.index]
                                                            .cantidad_devuelta
                                                            .$params.maxLength
                                                            .max
                                                    }}
                                                    caracteres
                                                </b-form-invalid-feedback>
                                            </span>
                                            <span v-else>{{
                                                $v.factura_venta_productosCopy
                                                    .$each[data.index]
                                                    .cantidad_devuelta.$model
                                            }}</span>
                                        </template> -->

                                        <template
                                            #cell(porcentaje_descuento)="data"
                                        >
                                            <span>{{
                                                $v.factura_venta_productosCopy
                                                    .$each[data.index]
                                                    .porcentaje_descuento.$model
                                            }}</span>
                                        </template>

                                        <template #cell(total_iva)="data">
                                            {{
                                                data.item.tarifa_iva.porcentaje
                                            }}%
                                        </template>

                                        <template
                                            #cell(total_impoconsumo)="data"
                                        >
                                            {{
                                                currencyFormat(
                                                    calcTotalImpoconsumo(data).toFixed(2)
                                                )
                                            }}
                                        </template>

                                        <template #cell(total)="data">
                                            {{
                                                currencyFormat(calcTotal(data).toFixed(2))
                                            }}
                                        </template>

                                        <template #cell(acciones)="row">
                                            <feather
                                                v-if="
                                                    read_only ||
                                                    !row.item.item_id ||
                                                    devolucion_factura.factura_proveedor_id
                                                "
                                                v-b-popover.hover.top="
                                                    'Eliminar producto'
                                                "
                                                type="trash-2"
                                                class="text-secondary"
                                                size="1.3rem"
                                            />

                                            <feather
                                                v-else
                                                v-b-popover.hover.top="
                                                    'Eliminar producto'
                                                "
                                                type="trash-2"
                                                class="text-error"
                                                size="1.3rem"
                                                role="button"
                                                @click="confirmRemoveItem(row)"
                                            />

                                            <feather
                                                :type="
                                                    row.detailsShowing
                                                        ? 'arrow-up-circle'
                                                        : 'arrow-down-circle'
                                                "
                                                v-b-popover.hover.top="
                                                    row.detailsShowing
                                                        ? 'Ocultar detalles'
                                                        : 'Ver detalles'
                                                "
                                                @click="
                                                    ($evt) => {
                                                        row.toggleDetails($evt)
                                                        setBodegaAndLote(
                                                            row.item
                                                        )
                                                    }
                                                "
                                                size="1.3rem"
                                                role="button"
                                                class="text-secondary ml-2"
                                            />
                                        </template>

                                        <template #foot()="data">
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'referencia'
                                                "
                                            >
                                                <b>Subtotal</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.subtotal_neto.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'descripcion'
                                                "
                                            >
                                                <b>Descuento</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.descuento.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'porcentaje_descuento'
                                                "
                                            >
                                                <b>Iva</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.total_iva.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'cantidad'
                                                "
                                            >
                                                <b>Impoconsumo</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.total_ipoconsumo.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key === 'valor'
                                                "
                                            >
                                                <b>Retefuente</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            total_retefuente.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <!-- <div
                                                v-if="
                                                    data.field.key ===
                                                    'cantidad_devuelta'
                                                "
                                            >
                                                <b>Reteiva</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            total_reteiva
                                                        )
                                                    }}
                                                </div>
                                            </div> -->
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'acciones'
                                                "
                                            >
                                                <b>Total</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            granTotal.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </template>

                                        <template #row-details="row">
                                            <b-card>
                                                <!-- {{ row.item }} -->
                                                <b-row class="pa-2">
                                                    <b-col cols="2">
                                                        <b>Descuento</b>
                                                        <div>
                                                            {{
                                                                currencyFormat(
                                                                    row.item
                                                                        .descuento.toFixed(2)
                                                                )
                                                            }}
                                                        </div>
                                                    </b-col>
                                                    <b-col cols="2">
                                                        <label for="bodega_id"
                                                            >Bodega</label
                                                        >
                                                        <!-- <jautocomplete
                                                            id="bodega_id"
                                                            :items="bodegas"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .bodega_id
                                                            "
                                                            disabled
                                                        /> -->
                                                        <jautocomplete
                                                            id="bodega_id"
                                                            :items="bodegas"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .bodega_id
                                                            "
                                                            @change="
                                                                getExistenciaProducto(
                                                                    row
                                                                )
                                                            "
                                                            :disabled="
                                                                read_only ||
                                                                !row.item
                                                                    .item_id ||
                                                                $v
                                                                    .devolucion_factura
                                                                    .factura_proveedor_id
                                                                    .$model !==
                                                                    null
                                                            "
                                                        />
                                                        <!-- <div>
                                                            {{
                                                                bodegas.find(
                                                                    (b) =>
                                                                        b.id ===
                                                                        row.item
                                                                            .bodega_id
                                                                )?.descripcion
                                                            }}
                                                        </div> -->
                                                    </b-col>
                                                    <b-col cols="2">
                                                        <label
                                                            for="lote_inventario_id"
                                                            >Lote de
                                                            inventario</label
                                                        >
                                                        <!-- <jautocomplete
                                                            id="lote_inventario_id"
                                                            :items="
                                                                lotes_inventario
                                                            "
                                                            item-text="nombre"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .lote_inventario_id
                                                            "
                                                            disabled
                                                        /> -->
                                                        <jautocomplete
                                                            id="lote_inventario_id"
                                                            :items="
                                                                lotes_inventario
                                                            "
                                                            item-text="nombre"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .lote_inventario_id
                                                            "
                                                            @change="
                                                                getExistenciaProducto(
                                                                    row
                                                                )
                                                            "
                                                            :disabled="
                                                                read_only ||
                                                                !row.item
                                                                    .item_id ||
                                                                $v
                                                                    .devolucion_factura
                                                                    .factura_proveedor_id
                                                                    .$model !==
                                                                    null
                                                            "
                                                        />
                                                    </b-col>

                                                    <b-col cols="2">
                                                        <label
                                                            for="tercero_id"
                                                            >Tercero</label
                                                        >
                                                        <jautocomplete
                                                            id="tercero_id"
                                                            :items="tercerosCopy"
                                                            item-value="id"
                                                            item-text="n_documento"
                                                            :custom-filter="filterTerceros"
                                                            open-on-focus
                                                            :custom-text-field="textFieldTercero"
                                                            v-model="row.item.tercero_id"
                                                            show-create-button
                                                            :disabled="read_only ||
                                                                !row.item.item_id ||
                                                                !permisos.includes('devolucion_proveedor-modificar-tercero')
                                                                "
                                                        >
                                                            <template v-slot:option="{ item }">
                                                                <div
                                                                    class="p-1 option"
                                                                    role="button"
                                                                >
                                                                    {{
                                                                        item.tipo_documento_id ===
                                                                        '31'
                                                                        ? item.razon_social
                                                                        : `${item.nombre1} ${item.apellido1}`
                                                                    }}
                                                                </div>
                                                            </template>
                                                        </jautocomplete>
                                                    </b-col>

                                                    <b-col cols="2">
                                                        <label
                                                            for="concepto_contable_id"
                                                            >Concepto contable</label
                                                        >
                                                        <jautocomplete
                                                            id="concepto_contable_id"
                                                            :items="conceptos_contables
                                                                    "
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="row.item
                                                                .concepto_contable_id
                                                                "
                                                            :disabled="read_only ||
                                                                !row.item
                                                                    .item_id || !permisos.includes('devolucion_proveedor-modificar-concepto-contable')
                                                                "
                                                        />
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </template>
                                    </b-table>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="productsLengthError"
                                    >
                                        La lista de productos debe tener al
                                        menos
                                        {{
                                            $v.factura_venta.productos.$params
                                                .minLength.min
                                        }}
                                        producto
                                    </div>
                                </b-col>
                            </b-row>
                            <!-- <b-row class="mt-4" v-if="plazo_vencimiento == 0">
                                <b-col cols="12">
                                    <label class="mb-4 font-weight-bold"
                                        >Formas de pago</label
                                    >
                                    <div
                                        v-for="(
                                            item, index
                                        ) in factura_venta.formas_pago"
                                        :key="index"
                                        class="my-3"
                                    >
                                        <b-row
                                            class="d-flex flex-row align-items-center"
                                        >
                                            <b-col cols="12" sm="5">
                                                <b-input-group>
                                                    <jautocomplete
                                                        :id="`forma_pago_${index}`"
                                                        :items="formas_pago"
                                                        item-value="id"
                                                        item-text="descripcion"
                                                        open-on-focus
                                                        v-model="
                                                            $v.factura_venta
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .$model
                                                        "
                                                        :state="
                                                            validateStateFormasPago(
                                                                index,
                                                                'forma_pago_id'
                                                            )
                                                        "
                                                        :disabled="
                                                            read_only ||
                                                            devolucion_factura.factura_proveedor_id !==
                                                                null
                                                        "
                                                    />
                                                    <div
                                                        class="invalid-field mt-1"
                                                        v-if="
                                                            !$v.factura_venta
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .required &&
                                                            $v.factura_venta
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .$error
                                                        "
                                                    >
                                                        Campo requerido
                                                    </div>
                                                    <div
                                                        class="invalid-field mt-1"
                                                        v-if="
                                                            !$v.factura_venta
                                                                .formas_pago
                                                                .$each[index]
                                                                .forma_pago_id
                                                                .noRepeat
                                                        "
                                                    >
                                                        No se admiten duplicados
                                                    </div>
                                                </b-input-group>
                                            </b-col>
                                            <b-col cols="12" sm="5">
                                                <currencyInput
                                                    v-model="
                                                        $v.factura_venta
                                                            .formas_pago.$each[
                                                            index
                                                        ].value.$model
                                                    "
                                                    :state="
                                                        validateStateFormasPago(
                                                            index,
                                                            'value'
                                                        )
                                                    "
                                                    :disabled="
                                                        read_only ||
                                                        devolucion_factura.factura_proveedor_id !==
                                                            null
                                                    "
                                                />
                                                <div
                                                    class="invalid-field mt-1"
                                                    v-if="
                                                        !$v.factura_venta
                                                            .formas_pago.$each[
                                                            index
                                                        ].value.minValue &&
                                                        $v.factura_venta
                                                            .formas_pago.$each[
                                                            index
                                                        ].value.$error
                                                    "
                                                >
                                                    Campo requerido
                                                </div>
                                            </b-col>
                                            <b-col cols="12" sm="2">
                                                <feather
                                                    type="plus"
                                                    role="button"
                                                    v-b-popover.hover.top="
                                                        'Agregar'
                                                    "
                                                    @click="
                                                        read_only ||
                                                        devolucion_factura.factura_proveedor_id !==
                                                            null
                                                            ? (() => {})()
                                                            : addFormaPago()
                                                    "
                                                ></feather>
                                                <feather
                                                    v-if="
                                                        factura_venta
                                                            .formas_pago
                                                            .length > 1
                                                    "
                                                    type="trash-2"
                                                    role="button"
                                                    class="ml-2"
                                                    v-b-popover.hover.top="
                                                        'Eliminar'
                                                    "
                                                    @click="
                                                        read_only
                                                            ? (() => {})()
                                                            : deleteFormaPago(
                                                                  index
                                                              )
                                                    "
                                                ></feather>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                                <b-col cols="12">
                                    <label class="mb-4 font-weight-bold"
                                        >Cambio:</label
                                    >
                                    {{ currencyFormat(cambio) }}
                                </b-col>
                            </b-row> -->
                            <!-- <div v-if="$v.factura_venta.formas_pago.required">
                                Requerido
                            </div> -->
                        </b-form>
                    </b-form>
                    <prefijoModal
                        :consecutivos="consecutivos"
                        @selectedConsecutivo="setConsecutivo"
                    />
                    <!-- getNumeroFactura(consecutivo.id) -->
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <!-- <crearVendedoresModalVue @newVendedor="setNewVendedor" /> -->
        <crearEstablecimientoComercioModalVue
            @newEstablecimientoComercio="setNewEstablecimientoComercio"
        />
        <crearItemVue @newItem="setNewItem" />
        <modalItem @selectedItem="insertProducto" />
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import {
    required,
    maxLength,
    minLength,
    requiredIf
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { compareDates, dateNow, dateAddDays } from '@/utils/date'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    addListenerCommands,
    removeListenerCommands,
    goToNextField,
    onFocused,
    currencyFormat,
    compareObjects
} from '@/utils/others'
import { filterTerceros } from '@/utils/filters'
import { calcRetefuente } from '@/utils/facturaVenta'
import prefijoModal from '@/components/shared/prefijo-modal/prefijoModal'
import detalleFacturaVue from '@/components/shared/detalle-factura/detalleFactura.vue'
import currencyInput from '@/components/shared/currency-input/currency-input'
// import print from 'print-js'
// import crearVendedoresModalVue from '@/views/administrativo/configuracion/vendedores/crearVendedoresModal.vue'
import crearEstablecimientoComercioModalVue from '../../configuracion/establecimientos-comercio/crearEstablecimientoComercioModal.vue'
import crearItemVue from '../../inventario/items/crearItemModal.vue'
import modalItem from '@/components/shared/modal-items/modal-items.vue'

const { API } = services

const defaultFacturaVenta = () =>
    JSON.parse(
        JSON.stringify({
            nota_factura: '',
            numero_factura: '',
            fecha_factura: dateNow(),
            vencimiento: dateNow(),
            // cliente_id: null,
            gran_total: 0,
            subtotal: 0,
            total_reteiva: 0,
            total_descuento: 0,
            total_ica: 0,
            total_ipoconsumo: 0,
            total_iva: 0,
            total_retefuente: 0,
            consecutivo_factura: { prefijo: '' },
            tipo_documento_id: 2,
            estado_documento_id: null,
            subtotal_neto: 0,
            tercero_id: null,
            usuario_id: null,
            // vendedor_id: null,
            sede_id: null,
            empresa_id: null,
            configuracion_impuestos: {},
            formas_pago: [],
            productos: [],
            cambio: 0
        })
    )

const defaultDevolucion = () =>
    JSON.parse(
        JSON.stringify({
            tipo_devolucion_id: null,
            factura_proveedor_id: null,
            consecutivo: { prefijo: '' },
            numero: '',
            fecha: dateNow(),
            nota: '',
            vencimiento: dateNow()
        })
    )

const defaultProducto = () =>
    JSON.parse(
        JSON.stringify({
            descripcion: '',
            cantidad: 0,
            // cantidad_devuelta: 0,
            descuento: 0,
            ipoconsumo: 0,
            porcentaje_descuento: 0,
            precio: 0,
            retefuente: 0,
            precio_base_venta: 0,
            valor: 0,
            valor_ica: 0,
            valor_iva: 0,
            subtotal: 0,
            subtotal_neto: 0,
            total: 0,
            cliente_id: null,
            bodega_id: null,
            lote_inventario_id: null,
            concepto_contable_id: null,
            tercero_id: null,
            item_id: null,
            factura_item_id: null,
            _showDetails: false
        })
    )

const defaultFormaPago = () => JSON.parse(JSON.stringify({
    forma_pago_id: null,
    value: 0
}))

export default {
    name: 'CrearDevolucionProveedor',
    mixins: [validationMixin],
    components: {
        jautocomplete,
        prefijoModal,
        detalleFacturaVue,
        currencyInput,
        // crearVendedoresModalVue,
        crearEstablecimientoComercioModalVue,
        crearItemVue,
        modalItem
    },
    data: () => ({
        conceptos_contables: [],
        terceros: [],
        bodegas: [],
        lotes_inventario: [],
        tercerosCopy: [],
        productos: [],
        // vendedores: [],
        tipos_devoluciones: [],
        plazo_vencimiento: null,
        productsLengthError: null,
        detalle_factura: {},
        compras: [],
        retencionBaseData: {
            tercero: {
                declarante: false,
                gran_contribuyente: false,
                agente_retencion_fuente: false,
                regimen_simple_tributacion: false
            },
            empresa: {
                agente_retencion_fuente: false,
                autoretenedor_fuente: false
            }
        },
        titulosProductos: [
            {
                key: 'referencia',
                label: 'Referencia',
                class: 'text-center',
                thStyle: { maxWidth: '10rem', textAlign: 'center' }
            },
            {
                key: 'descripcion',
                label: 'Producto',
                class: 'text-center',
                thStyle: { minWidth: '18rem', textAlign: 'center' }
            },
            {
                key: 'cantidad',
                label: 'Cantidad',
                class: 'text-center',
                thStyle: { textAlign: 'center', maxWidth: '7rem' }
            },
            {
                key: 'valor',
                label: 'Valor',
                class: 'text-center',
                thStyle: { textAlign: 'center', minWidth: '12rem' }
            },
            {
                key: 'porcentaje_descuento',
                label: '% Descuento',
                class: 'text-center',
                thStyle: { minWidth: '7rem',  textAlign: 'center' }
            },
            // { key: 'descuento', label: 'Descuento', class: 'text-center' },
            {
                key: 'total_iva',
                label: 'Iva',
                class: 'text-center',
                thStyle: { width: '10%', textAlign: 'center' }
            },
            {
                key: 'total_impoconsumo',
                label: 'Total impoconsumo',
                class: 'text-center',
                thStyle: { textAlign: 'center' }
            },
            {
                key: 'total',
                label: 'Total',
                class: 'text-center',
                thStyle: { width: '10%', textAlign: 'center' }
            },
            {
                key: 'acciones',
                label: 'Acciones',
                class: 'text-center',
                thStyle: { textAlign: 'center' }
            }
        ],
        total_retefuente: 0,
        total_reteiva: 0,
        proveedores: [],
        consecutivos: [],
        factura_venta_copy: defaultFacturaVenta(),
        factura_venta: defaultFacturaVenta(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        formas_pago: [],
        devolucion_factura: defaultDevolucion(),
        search: '',
        factura_venta_productosCopy: []
    }),
    watch: {
        factura_venta: {
            handler(newValue) {
                const productos = newValue.productos
                
                if (productos.length > 0) {
                    this.getTotalRetefuente(productos)
                }
                
                const { params: { devolucion_id } } = this.$route
                
                if (!devolucion_id) {
                    this.isSaved = compareObjects(this.factura_venta_copy, newValue)
                } else {
                    this.isSaved = true
                }
            },
            deep: true
        },
        factura_venta_productosCopy: {
            handler(newValue) {
                const productos = newValue
                
                this.factura_venta.productos = [...productos]
                this.factura_venta_copy.productos = [...productos]
            },
            deep: true
        }
    },
    mounted() {
        addListenerCommands(this)
        this.getConceptosContables()
        this.getParams()
        this.getTerceros()
        this.getConsecutivos()
        this.getTiposDevolucion()
        this.addBlankProduct()
        setTimeout(() => {
            this.getLotesInventario()
            this.getBodegas()
            this.getFormasPago()
            this.getProveedores({ term: '' })
        }, 500)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved || this.read_only) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        factura_venta: {
            fecha_factura: {
                required,
                compareDates: (d, a) => {
                    if (a.vencimiento) {
                        return compareDates(d, a.vencimiento)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            vencimiento: {
                required
            },
            proveedor_id: {
                required
            },
            consecutivo_factura: {
                prefijo: {
                    required: requiredIf(function () {
                        // console.log(this.devolucion_factura)
                        return (
                            !this.devolucion_factura.factura_proveedor_id ===
                            null
                        )
                    })
                }
            },
            numero_factura: {
                required: requiredIf(function () {
                    // console.log(this.devolucion_factura)
                    return (
                        !this.devolucion_factura.factura_proveedor_id === null
                    )
                })
            },
            nota_factura: {},
            productos: {
                minLength: minLength(1),
                $each: {
                    descripcion: {
                        required
                    },
                    valor: {
                        // required: (data, model) => {
                        //     if (
                        //         model.item_id !== null &&
                        //         model.item_id !== undefined &&
                        //         model.item_id !== ''
                        //     ) {
                        //         return data > 0
                        //     } else {
                        //         return true
                        //     }
                        // }
                    },
                    cantidad: {
                        required: (data, model) => {
                            if (
                                model.item_id !== null &&
                                model.item_id !== undefined &&
                                model.item_id !== ''
                            ) {
                                return data > 0
                            } else {
                                return true
                            }
                        },
                        maxLength: maxLength(10)
                    },
                    // cantidad_devuelta: {
                    //     required: (data, model) => {
                    //         if (
                    //             model.item_id !== null &&
                    //             model.item_id !== undefined &&
                    //             model.item_id !== ''
                    //         ) {
                    //             return data > 0
                    //         } else {
                    //             return true
                    //         }
                    //     },
                    //     maxLength: maxLength(10)
                    // },
                    porcentaje_descuento: {
                        maxLength: maxLength(6)
                    },
                    bodega_id: {},
                    lote_inventario_id: {}
                }
            }
            // formas_pago: {
            //     $each: {
            //         forma_pago_id: {
            //             noRepeat: function (value) {
            //                 const filteredFp =
            //                     this.factura_venta.formas_pago.filter(
            //                         (f) => f.forma_pago_id === value
            //                     )

            //                 if (filteredFp.length > 1) {
            //                     return false
            //                 } else {
            //                     return true
            //                 }
            //             },
            //             required: requiredIf(function () {
            //                 return !this.plazo_vencimiento != 0
            //             })
            //         },
            //         value: {
            //             minValue: function (model) {
            //                 if (this.plazo_vencimiento != 0) {
            //                     return true
            //                 }
            //                 return !model == 0
            //             }
            //         }
            //     }
            // }
        },
        factura_venta_productosCopy: {
            // minLength: data => {
            //     return data.length > 0
            // },
            minLength: minLength(1),
            $each: {
                descripcion: {
                    required
                },
                valor: {
                    // required: (data, model) => {
                    //     if (
                    //         model.item_id !== null &&
                    //         model.item_id !== undefined &&
                    //         model.item_id !== ''
                    //     ) {
                    //         return data > 0
                    //     } else {
                    //         return true
                    //     }
                    // }
                },
                cantidad: {
                    required: (data, model) => {
                        if (
                            model.item_id !== null &&
                            model.item_id !== undefined &&
                            model.item_id !== ''
                        ) {
                            return data > 0
                        } else {
                            return true
                        }
                    },
                    maxLength: maxLength(10)
                },
                // cantidad_devuelta: {
                //     required: (data, model) => {
                //         if (
                //             model.item_id !== null &&
                //             model.item_id !== undefined &&
                //             model.item_id !== ''
                //         ) {
                //             return data > 0
                //         } else {
                //             return true
                //         }
                //     },
                //     maxLength: maxLength(10)
                // },
                porcentaje_descuento: {
                    maxLength: maxLength(6)
                },
                bodega_id: {},
                lote_inventario_id: {},
                tercero_id: {},
                concepto_contable_id: {}
            }
        },
        devolucion_factura: {
            tipo_devolucion_id: {
                required
            },
            factura_proveedor_id: {},
            consecutivo_proveedor: {
                // required
            },
            nota: {
                maxLength: maxLength(500)
            },
            fecha: {
                required,
                compareDates: (d, a) => {
                    if (a.vencimiento) {
                        return compareDates(d, a.vencimiento)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            consecutivo: {
                prefijo: {
                    required
                }
            },
            numero: {
                required
            },
            vencimiento: {
                required
            }
        },
        plazo_vencimiento: {
            noNegativeNumbers: (data) => {
                const number = Number(data)
                return number > -1
            }
        }
    },
    methods: {
        onFocused,
        currencyFormat,
        goToNextField,
        filterTerceros,
        searchData() {
            if (this.search) {
                // console.log(this.search)
                this.factura_venta_productosCopy =
                    this.factura_venta.productos.filter(
                        (p) =>
                            p.descripcion
                                .toLowerCase()
                                .includes(this.search.toLowerCase()) ||
                            p.referencia.includes(this.search)
                    )
            } else {
                this.factura_venta_productosCopy = JSON.parse(
                    JSON.stringify(this.factura_venta.productos)
                )
            }
        },
        setFactura(factura) {
            this.factura_venta_productosCopy = []
            const {
                fecha,
                nota,
                // id,
                numero,
                sede_id,
                empresa_id,
                consecutivo,
                proveedor_id,
                vencimiento,
                productos,
                formas_pago,
                total_ica,
                total_iva,
                tipo_documento_id,
                plazo
            } = factura

            let products_devolucion = productos || []

            // if (!this.read_only) {
            //     products_devolucion = productos.map((p) => ({
            //         ...p,
            //         cantidad_devuelta: 0
            //     }))
            // }

            this.factura_venta_productosCopy = JSON.parse(
                JSON.stringify(products_devolucion)
            )

            const devolucion = {
                fecha_factura: fecha,
                vencimiento,
                proveedor_id,
                nota_factura: nota,
                numero_factura: numero,
                sede_id,
                empresa_id,
                consecutivo_factura: consecutivo,
                productos: products_devolucion,
                formas_pago,
                total_ica,
                total_iva,
                tipo_documento_id
            }

            // console.log(devolucion, 'devolucion')

            // this.devolucion_factura.factura_proveedor_id = id

            this.factura_venta = {
                ...this.factura_venta,
                ...devolucion
            }

            // console.log(this.factura_venta, 'factura_venta')

            this.factura_venta_copy = {
                ...this.factura_venta,
                ...devolucion
            }

            // const pc =
            //     plazo !== null || plazo !== undefined
            //         ? plazo
            //         : daysLefts(fecha, vencimiento)
            setTimeout(() => {
                this.assignProveedor(proveedor_id, plazo)
                this.getTotalRetefuente(productos)
            }, 500)

            // setTimeout(() => {
            //     formas_pago.forEach((forma, index) => {
            //         // console.log(forma)
            //         const input_fp = document.getElementById(
            //             `forma_pago_${index}`
            //         )
            //         input_fp.value = forma.forma_pago_descripcion
            //     })
            // }, 500)
        },
        textFieldFactura(factura) {
            if (factura) {
                const {
                    numero,
                    consecutivo: { prefijo }
                } = factura
                return `${prefijo} - ${numero}`
            }
            return ''
        },
        getComprasProveedor(term) {
            API.POST({
                url: 'administracion/factura-proveedor/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL_BY_NUMERO_PREFIJO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.compras = dato.map((f) => ({
                            ...f,
                            consecutivo: {
                                ...f.consecutivo,
                                prefijo:
                                    f.consecutivo.prefijo === ''
                                        ? 'Sin prefijo'
                                        : f.consecutivo.prefijo
                            }
                        }))
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposDevolucion() {
            API.POST({
                url: 'administracion/tipo-devolucion/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_devoluciones = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        showLog(data) {
            console.log(data)
        },
        setNewItem(item) {
            this.productos.push(item)
            // console.log(item)
            this.insertProducto(item)
        },
        setNewEstablecimientoComercio(proveedor) {
            // console.log(proveedor)
            this.proveedores.push(proveedor)
            this.assignProveedor(proveedor.id)
        },
        setBodegaAndLote(item) {
            const { bodega_id, lote_inventario_id, concepto_contable_id, tercero_id } = item
            item.bodega_id = null
            item.lote_inventario_id = null
            item.concepto_contable_id = null
            item.tercero_id = null

            setTimeout(() => {
                // console.log(bodega_id, lote_inventario_id)
                item.bodega_id = bodega_id
                item.lote_inventario_id = lote_inventario_id
                item.concepto_contable_id = concepto_contable_id
                item.tercero_id = tercero_id
            }, 100)
        },
        getConceptosContables() {
            API.POST({
                url: 'administracion/concepto-digitacion/all',
                data: {
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.conceptos_contables = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                mensaje_exception ||
                                'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        addFormaPago() {
            this.factura_venta.formas_pago.push(defaultFormaPago())
        },
        deleteFormaPago(index) {
            this.factura_venta.formas_pago.splice(index, 1)
        },
        getFormasPago() {
            API.POST({
                url: 'administracion/forma-pago/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log('a')
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log('fp')
                        this.formas_pago = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        setConsecutivo(consecutivo) {
            this.devolucion_factura.consecutivo = consecutivo
            this.devolucion_factura.numero = consecutivo.secuencia

            setTimeout(() => {
                const ELEMENT = document.getElementById('factura_proveedor_id')
                ELEMENT.focus()
            }, 500)
        },
        checkPrefijo() {
            const isSelected =
                this.devolucion_factura.consecutivo?.prefijo !== '' &&
                this.devolucion_factura.consecutivo?.prefijo !== null
            if (!isSelected) {
                this.$bvModal.show('modal-prefijo')
            }
        },
        confirmRemoveItem(data) {
            const {
                item: { descripcion },
                index
            } = data

            this.$bvModal
                .msgBoxConfirm(
                    `Está seguro que desea eliminar el producto "${descripcion}" de la lista de productos de la factura de venta?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.removeItemFromFacturaVenta(index)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        removeItemFromFacturaVenta(index) {
            // this.factura_venta.productos.splice(index, 1)
            this.factura_venta_productosCopy.splice(index, 1)
        },
        calcTotalIva(data) {
            const result = Number(
                ((data.item.valor * data.item.cantidad -
                    // - data.item.cantidad_devuelta
                    (data.item.valor *
                        data.item.cantidad *
                        // - data.item.cantidad_devuelta
                        data.item.porcentaje_descuento) /
                        100) *
                    data.item.tarifa_iva.porcentaje) /
                    100
            )
            // this.factura_venta.productos[data.index].total_iva = result
            // this.factura_venta.productos[data.index].valor_iva = result
            this.factura_venta_productosCopy[data.index].total_iva = result
            this.factura_venta_productosCopy[data.index].valor_iva = result
            return result
        },
        calcDescuento(data) {
            const result = Number(
                data.item.cantidad *
                    data.item.valor *
                    (data.item.porcentaje_descuento / 100)
            )
            this.factura_venta_productosCopy[data.index].descuento = result
            return result
        },
        calcTotalImpoconsumo(data) {
            // se verifica si el impoconsumo es porecntaje (0) y se hace el cálculo
            // sino es porcentaje se devuelve el valor
            // console.log(data.item.tipo_ipoconsumo == 0 ? 'porcentaje' : 'vslor')
            const {
                tipo_ipoconsumo,
                valor_ipoconsumo,
                porcentaje_descuento,
                valor,
                cantidad
                // cantidad_devuelta
            } = data.item
            let impoconsumo

            if (tipo_ipoconsumo == 0) {
                const descuento = (valor * porcentaje_descuento) / 100
                const valorNeto = valor - descuento
                impoconsumo = (valorNeto * valor_ipoconsumo) / 100
            } else if (tipo_ipoconsumo == 1) {
                impoconsumo = valor_ipoconsumo
            } else {
                impoconsumo = 0
            }

            const result = Number(
                impoconsumo * cantidad
                // - cantidad_devuelta
            )
            // this.factura_venta.productos[data.index].total_ipoconsumo = result
            this.factura_venta_productosCopy[data.index].total_ipoconsumo =
                result
            return result
        },
        calcTotal(data) {
            const { cantidad, valor, total_iva, descuento } = data.item
            const subtotal = Number(cantidad * valor)
            const total = subtotal + total_iva - descuento
            this.factura_venta_productosCopy[data.index].total = total
            this.factura_venta_productosCopy[data.index].subtotal_neto =
                subtotal
            return total

            // const { cantidad, valor, descuento /* cantidad_devuelta*/ } =
            //     data.item
            // const result = Math.round(cantidad /*- cantidad_devuelta*/ * valor)
            // const t = Math.round(
            //     cantidad /*- cantidad_devuelta*/ * valor - descuento
            // )
            // this.factura_venta_productosCopy[data.index].total = result
            // this.factura_venta_productosCopy[data.index].subtotal_neto = t
            // return result
        },
        calcValor(data) {
            const {
                index,
                item: {
                    precio_base_venta,
                    tarifa_iva,
                    tipo_ipoconsumo,
                    valor_ipoconsumo
                }
            } = data
            const porcentaje_iva = 1 + tarifa_iva.porcentaje / 100
            const valorNeto =
                tipo_ipoconsumo == 0 || tipo_ipoconsumo === 'NA'
                    ? precio_base_venta / porcentaje_iva
                    : (precio_base_venta - valor_ipoconsumo) / porcentaje_iva
            const result = Number(valorNeto)

            // this.factura_venta.productos[index].valor = result
            this.factura_venta_productosCopy[index].valor = result

            this.calcTotalIva(data)
            this.calcDescuento(data)

            return result
        },
        filterProductos(text) {
            if (text && text.length > 3) {
                this.getProductos({ term: text })
            }
        },
        filterFacturas(text) {
            if (text && text.length > 0) {
                // this.getProductos({ term: text })
                this.getComprasProveedor(text)
            }
        },
        filterCliente(text) {
            if (text && text.length > 3) {
                this.getProveedores({ term: text })
            }
        },
        getNumeroFactura(id) {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            id //id del consecutivo
                        },
                        p_opc: 'GET_NUMERO_SECUENCIA',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.factura_venta.numero = dato.numero
                            // this.consecutivos = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getLotesInventario() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.lotes_inventario = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getProveedores({ term }) {
            API.POST({
                url: 'general/tercero/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL_PROVEEDORES',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.proveedores = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getConsecutivos() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            clase_documento_id: 7,
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL_BY_CLASE_DOCUMENTO',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.consecutivos = dato.map((c) => {
                                return c.prefijo === ''
                                    ? {
                                          ...c,
                                          prefijo: 'Sin prefijo'
                                      }
                                    : c
                            })
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        textFieldTercero(tercero) {
            return tercero.tipo_documento_id === '31'
                ? tercero.razon_social
                : `${tercero.nombre1} ${tercero.apellido1}`
        },
        getParams() {
            const { devolucion_id, compra_id } = this.$route.params
            // console.log(devolucion_id)
            if (devolucion_id) {
                this.getProveedores({ term: '' })
                this.getDevolucionFactura(devolucion_id)
            } else if (compra_id) {
                this.getComprasProveedor('')
                this.getCompraWithDevoluciones(compra_id)
                this.checkPrefijo()
                this.addFormaPago()
                this.factura_venta_copy.formas_pago.push(defaultFormaPago())
            } else {
                this.checkPrefijo()
                this.addFormaPago()
                this.factura_venta_copy.formas_pago.push(defaultFormaPago())
            }

            this.read_only = this.$route.meta.read_only
        },
        getCompraWithDevoluciones(id) {
            API.POST({
                url: 'administracion/factura-proveedor/query',
                data: {
                    p_datajson: {
                        id,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_ID_WITH_DEVOLUCIONES',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        if (!dato.productos || dato.productos.length === 0 || dato.devuelto) {
                            this.$bvModal
                                .msgBoxOk(
                                    'La factura ya ha sido devuelta en su totalidad',
                                    {
                                        title: 'Advertencia!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'warning',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'warning',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            this.devolucion_factura = {
                                ...this.devolucion_factura,
                                consecutivo_proveedor: dato.consecutivo_proveedor,
                                factura_proveedor_id: dato.id
                            }
                            const facturaVentaIdInput = document.getElementById(
                                'factura_proveedor_id'
                            )
                            // console.log(facturaVentaIdInput)
                            facturaVentaIdInput.value = `${
                                dato.consecutivo.prefijo === ''
                                    ? 'Sin prefijo'
                                    : dato.consecutivo.prefijo
                            } - ${dato.numero}`
                            this.setFactura(dato)
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getDevolucionFactura(id) {
            // console.log('acá 2');
            API.POST({
                url: 'administracion/devolucion-proveedor/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        const {
                            fecha,
                            numero,
                            consecutivo,
                            nota,
                            factura_proveedor_id,
                            tipo_devolucion_id,
                            plazo,
                            proveedor_id,
                            configuracion_impuestos
                        } = dato
                        this.devolucion_factura = {
                            fecha,
                            numero,
                            consecutivo,
                            nota,
                            factura_proveedor_id:
                                factura_proveedor_id === 0
                                    ? null
                                    : factura_proveedor_id,
                            tipo_devolucion_id
                        }

                        // console.log(dato)

                        const facturaVentaIdInput = document.getElementById(
                            'factura_proveedor_id'
                        )
                        facturaVentaIdInput.value = `${
                            dato.consecutivo_factura.prefijo
                                ? dato.consecutivo_factura.prefijo
                                : 'Sin prefijo'
                        } - ${dato.numero_factura}`

                        this.setFactura(dato)
                        // console.log(dato)
                        // const retrieveFacturaVenta = {
                        //     ...dato,
                        //     numero: dato.consecutivo.secuencia,
                        //     productos: dato.configuracion_impuestos.productos
                        // }
                        // this.factura_venta_copy = {
                        //     ...retrieveFacturaVenta
                        // }
                        // this.factura_venta = {
                        //     ...retrieveFacturaVenta
                        // }
                        // const pc = daysLefts(fecha, vencimiento)
                        setTimeout(() => {
                            this.assignProveedor(proveedor_id, plazo)
                            this.getTotalRetefuente(
                                configuracion_impuestos.productos
                            )
                        }, 500)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTerceros() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'general/tercero/query',
                    data: {
                        p_datajson: {
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.terceros = dato
                            this.tercerosCopy = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        addBlankProduct() {
            const producto = {
                ...defaultProducto(),
                cantidad: 0,
                referencia: '',
                descripcion: 'No seleccionado',
                precio_base_venta: 0,
                valor: 0,
                porcentaje_descuento: 0,
                descuento: 0,
                valor_iva: 0,
                total_iva: 0,
                tipo_ipoconsumo: 'NA',
                valor_ipoconsumo: 0,
                total_ipoconsumo: 0,
                retencion: null,
                bodega_id: 0,
                lote_inventario_id: 0,
                tarifa_iva: { porcentaje: 0 },
                item_id: null,
                total: 0
            }

            // this.factura_venta.productos = [
            //     producto,
            //     ...this.factura_venta.productos
            // ]

            this.factura_venta_productosCopy = [
                producto,
                ...this.factura_venta_productosCopy
            ]
        },
        insertProducto(product) {
            // console.log(product)
            const {
                descripcion,
                precio_base_venta,
                id: item_id,
                referencia,
                tipo_ipoconsumo,
                valor_ipoconsumo,
                iva: tarifa_iva,
                retencion,
                bodega_id,
                lote_inventario_id,
                tipo_item_id,
                concepto_contable_id
            } = product
            
            const producto = {
                ...defaultProducto(),
                referencia,
                tipo_item_id,
                descripcion,
                precio_base_venta,
                valor: 0,
                porcentaje_descuento: 0,
                descuento: 0,
                valor_iva: 0,
                total_iva: 0,
                tipo_ipoconsumo,
                valor_ipoconsumo,
                total_ipoconsumo: 0,
                retencion,
                bodega_id,
                lote_inventario_id,
                tarifa_iva,
                concepto_contable_id,
                tercero_id: this.factura_venta.tercero_id,
                item_id,
                total: 0
            }

            // this.factura_venta.productos = [
            //     ...this.factura_venta.productos,
            //     producto
            // ]

            // this.factura_venta_productosCopy = [
            //     ...this.factura_venta_productosCopy,
            //     producto
            // ]

            const [emptyItem, ...rest] = this.factura_venta_productosCopy

            this.factura_venta_productosCopy = [emptyItem, producto, ...rest]

            // setTimeout(() => {
            //     const blank_referencia =
            //         document.getElementById(`referencia-null`)
            //     const blank_producto = document.getElementById(`producto-null`)
            //     blank_referencia.value = ''
            //     blank_producto.value = ''
            // }, 50)
            this.goToNextField(`cantidad-producto-${item_id}`)
            // this.$v.productos.$model.push(producto)
            // }
            // else {
            //     this.$notify({
            //         group: 'general',
            //         title: 'Producto existente',
            //         text: `${descripcion} ya ha sido registrado`,
            //         ignoreDuplicates: true,
            //         duration: 5000,
            //         type: 'error'
            //     })
            // }
        },
        getExistenciaProducto(data) {
            const {
                index,
                item: {
                    item_id: id,
                    bodega_id,
                    lote_inventario_id: lote_id,
                    cantidad,
                    tipo_item_id
                }
            } = data

            if (tipo_item_id == 1) {
                const year = new Date().getFullYear()
                API.POST({
                    url: 'administracion/inventario/item/query',
                    data: {
                        p_datajson: {
                            id,
                            bodega_id,
                            lote_id,
                            año: year
                        },
                        p_opc: 'FIND_BY_ID_EXISTENCIAS',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            const isNull = Array.isArray(dato)
                            if (!isNull) {
                                const cantidadNumber = Number(cantidad)
                                const { existencia } = dato
                                if (cantidadNumber > existencia) {
                                    this.factura_venta.productos[
                                        index
                                    ].cantidad = existencia
                                }
                            } else {
                                this.factura_venta.productos[index].cantidad = 0
                            }
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getProductos({ term }) {
            API.POST({
                // url: 'administracion/inventario/item/query',
                url: 'administracion/inventario/inventario-saldo/query',
                data: {
                    p_datajson: {
                        term,
                        bodega_id: this.getUltimaSede.bodega_id || 0,
                        lote_inventario_id:
                            this.getUltimaSede.lote_inventario_id || 0,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // this.productos = dato
                        this.productos = dato.content.map(
                            ({
                                lote_inventario_id,
                                bodega_id,
                                lote_inventario,
                                bodega,
                                item
                            }) => {
                                return {
                                    lote_inventario_id,
                                    lote_inventario,
                                    bodega_id,
                                    bodega,
                                    ...item
                                }
                            }
                        )
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        assignProveedor(proveedor_id, pc) {
            // console.log(cliente_id)
            if (pc !== undefined && pc !== null && pc !== '') {
                this.assignVencimiento(pc)
            }

            if (proveedor_id) {
                // console.log(this.clientes, cliente_id)
                const proveedor = this.proveedores.find(
                    (c) => c.id === proveedor_id
                )
                // console.log(cliente)
                const { id, plazo } = proveedor

                // console.log(cliente, plazo_credito)

                const {
                    declarante,
                    agente_retencion_fuente,
                    regimen_simple_tributacion,
                    gran_contribuyente
                } = proveedor

                const {
                    agente_retencion_fuente: arfEmpresa,
                    autoretenedor_fuente,
                    agente_retencion_iva,
                    gran_contribuyente: gcEmpresa
                } = this.getUltimaEmpresa

                this.retencionBaseData = Object.assign(this.retencionBaseData, {
                    tercero: {
                        declarante,
                        agente_retencion_fuente,
                        regimen_simple_tributacion,
                        gran_contribuyente
                    },
                    empresa: {
                        agente_retencion_fuente: arfEmpresa,
                        autoretenedor_fuente,
                        agente_retencion_iva,
                        gran_contribuyente: gcEmpresa
                    }
                })

                const products = this.factura_venta_productosCopy.map(p => {
                    let newProduct = null
                    if (p.tercero_id) {
                        newProduct = { ...p }
                    } else {
                        newProduct = { ...p, tercero_id: id }
                    }

                    return newProduct
                })

                this.factura_venta_productosCopy = products

                this.factura_venta.tercero_id = id
                this.$v.factura_venta.proveedor_id.$model = id
                this.assignVencimiento(plazo)
                // console.log(id);
            } else {
                this.factura_venta.proveedor_id = null
                this.factura_venta.tercero_id = null
                this.assignVencimiento(0)
            }
        },
        assignVencimiento(value) {
            const plazo = value ? Number(value) : 0

            this.$v.plazo_vencimiento.$model = plazo
            this.$v.devolucion_factura.vencimiento.$model = dateAddDays(
                this.devolucion_factura.fecha,
                plazo
            )

            // console.log(dateAddDays(this.devolucion_factura.fecha, plazo))

            // if (!this.$route.params.devolucion_id) {
            //     if (plazo === 0) {
            //         this.factura_venta.formas_pago = []
            //         this.addFormaPago()
            //     } else {
            //         this.factura_venta.formas_pago = []
            //     }
            //     this.$v.factura_venta.formas_pago.$reset()
            // }
        },
        onChangeFechaFactura() {
            if (this.factura_venta.proveedor_id) {
                const proveedor = this.proveedores.find(
                    (c) => c.id === this.factura_venta.proveedor_id
                )
                // console.log(cliente)
                const { plazo } = proveedor
                // console.log(plazo_credito)
                this.assignVencimiento(plazo)
            }
        },
        getTotalRetefuente(productos) {
            // const new_productos = productos.map((p) => {
            //     const { cantidad, cantidad_devuelta } = p
            //     return { ...p, cantidad: cantidad - cantidad_devuelta }
            // })
            const obj = calcRetefuente(productos, this.retencionBaseData)

            const {
                total_retefuente,
                total_reteiva,
                retencion_detail,
                reteiva_detail
            } = obj

            // console.log(reteiva_detail)

            const reteiva = Object.keys(reteiva_detail.gruposReteIva).map(
                (key) => {
                    // const valorGrupo = (reteiva_detail.gruposReteIva[key] * Number(key)) / 100
                    return {
                        group: key,
                        value: currencyFormat(
                            Number(reteiva_detail.gruposReteIva[key])
                        )
                    }
                }
            )

            const retencion = Object.keys(retencion_detail.gruposRetencion).map(
                (key) => {
                    const valorGrupo =
                        (retencion_detail.gruposRetencion[key] * Number(key)) /
                        100
                    return {
                        group: key,
                        base_retencion: currencyFormat(
                            retencion_detail.retenciones[key].base_retencion
                        ),
                        value: currencyFormat(Number(valorGrupo))
                    }
                }
            )

            // console.log(retencion, reteiva)

            this.detalle_factura = {
                ...this.detalle_factura,
                retencion,
                reteiva
            }

            this.total_retefuente = total_retefuente
            this.total_reteiva = total_reteiva
        },
        save() {
            if (this.devolucion_factura.factura_proveedor_id === null) {
                this.factura_venta_productosCopy.splice(0, 1)
                this.factura_venta.productos.splice(0, 1)
            }
            this.productsLengthError =
                this.factura_venta_productosCopy.length < 1
            // let comparisonFormasPagoGranTotal = true

            // if (this.plazo_vencimiento == 0) {
            //     comparisonFormasPagoGranTotal =
            //         this.formasPagoValue >= this.granTotal
            // }

            const valid =
                !this.$v.factura_venta.$invalid &&
                !this.$v.plazo_vencimiento.$invalid &&
                !this.productsLengthError &&
                !this.$v.devolucion_factura.$invalid

            // console.log(this.$v)
            if (valid) {
                // if (!comparisonFormasPagoGranTotal) {
                //     this.$notify({
                //         group: 'general',
                //         title: 'Valor de la factura',
                //         text: 'El valor total de las formas de pago no es mayor o igual al valor total de la factura',
                //         ignoreDuplicates: true,
                //         duration: 5000,
                //         type: 'error'
                //     })

                //     this.addBlankProduct()
                // } else {
                const {
                    total,
                    descuento: total_descuento,
                    total_ipoconsumo
                } = this.totalesFacturas

                const { consecutivo } = this.devolucion_factura

                const devolucion_factura = {
                    ...this.devolucion_factura,
                    consecutivo: {
                        ...consecutivo,
                        prefijo:
                            consecutivo.prefijo === 'Sin prefijo'
                                ? ''
                                : consecutivo.prefijo
                    }
                }

                // console.log(devolucion_factura)
                let productos_factura = []

                if (this.devolucion_factura.factura_proveedor_id === null) {
                    // console.log('a')
                    productos_factura = this.factura_venta.productos.map(
                        (p) => ({
                            ...p,
                            proveedor_id: this.factura_venta.proveedor_id
                        })
                    )
                } else {
                    productos_factura = this.factura_venta.productos
                }

                // this.factura_venta.productos = []

                // console.log(productos_factura)

                const factura_venta_extra_data = {
                    usuario_id: this.getUserData.id,
                    empresa_id: this.getUltimaEmpresa.id,
                    sede_id: this.getUltimaSede.id,
                    estado_documento_id: 1,
                    total,
                    gran_total: this.granTotal,
                    subtotal_neto: total - total_descuento,
                    subtotal: total,
                    total_descuento,
                    total_ipoconsumo,
                    total_retefuente: this.total_retefuente,
                    total_reteiva: this.total_reteiva,
                    cambio: this.cambio,
                    factura_proveedor_id:
                        this.devolucion_factura.factura_proveedor_id,
                    tipo_devolucion_id: this.tipo_devolucion_id,
                    configuracion_impuestos: {
                        retefuente: this.total_retefuente,
                        reteiva: this.total_reteiva,
                        productos: productos_factura
                    },
                    productos: productos_factura,
                    ...devolucion_factura
                }

                // const factura_venta = Object.assign(
                //     this.factura_venta,
                //     factura_venta_extra_data
                // )

                const factura_venta = {
                    ...this.factura_venta,
                    ...factura_venta_extra_data
                }

                // console.log(factura_venta)
                this.isLoading = true
                const p_opc = this.factura_venta.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'administracion/devolucion-proveedor/crud',
                    data: {
                        p_datajson: {
                            ...factura_venta
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                // dato: { document: documentBase64 },
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // if (this.factura_venta.consecutivo.auto_print) {
                            //     const base64 = documentBase64.replace(
                            //         'data:application/pdf;base64,',
                            //         ''
                            //     )
                            //     print({
                            //         printable: base64,
                            //         type: 'pdf',
                            //         base64: true
                            //     })
                            // }
                            // this.factura_venta = defaultFacturaVenta()
                            if (p_opc === 'INSERT') {
                                // this.$router.go()
                                // console.log(this.factura_venta.consecutivo)
                                // const consecutivo = JSON.parse(
                                //     JSON.stringify(
                                //         this.factura_venta.consecutivo
                                //     )
                                // )
                                consecutivo.secuencia += 1
                                this.factura_venta = defaultFacturaVenta()
                                this.factura_venta_productosCopy = []
                                // this.addBlankProduct()
                                // this.factura_venta.cliente_id = null
                                this.total_retefuente = 0
                                this.total_reteiva = 0
                                this.detalle_factura = {}
                                this.devolucion_factura = defaultDevolucion()
                                this.$v.devolucion_factura.$reset()
                                this.$v.factura_venta.$reset()
                                this.assignProveedor(null)

                                this.setConsecutivo(consecutivo)
                                const factura_id_value =
                                    document.getElementById(
                                        'factura_proveedor_id'
                                    )
                                factura_id_value.value = ''

                                this.addBlankProduct()

                                this.$notify({
                                    group: 'general',
                                    title: 'Devolución de compra Creada!',
                                    text: `La devolución de la compra se ha creado correctamente`,
                                    ignoreDuplicates: true,
                                    duration: 5000,
                                    type: 'success'
                                })
                            } else {
                                this.back()
                            }
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
                // }
            } else {
                if (this.devolucion_factura.factura_proveedor_id === null) {
                    this.addBlankProduct()
                }
                this.$v.factura_venta.$touch()
                this.$v.factura_venta_productosCopy.$touch()
                this.$v.devolucion_factura.$touch()
            }
        },
        getLabelCliente(item) {
            const {
                tipo_documento_id,
                nombre1,
                nombre2,
                apellido1,
                apellido2,
                razon_social,
                n_documento
            } = item
            let label = ''

            if (tipo_documento_id == 13) {
                label = `${nombre1}${nombre2 && ` ${nombre2}`} ${apellido1}${
                    apellido2 && ` ${apellido2}`
                }`
            } else {
                label = `${razon_social}`
            }

            label = `${label} - ${n_documento}`

            return label
        },
        getLabelItem(item) {
            const {
                descripcion,
                referencia,
                codigo_barra,
                contenido: existencia,
                precio_base_venta
            } = item
            let label = ''

            label = `${descripcion} - ${referencia} - ${codigo_barra} - ${existencia} - ${currencyFormat(
                precio_base_venta
            )}`

            return label
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            // console.log(key)
            const { $dirty, $error } = this.$v.factura_venta[key]
            return $dirty ? !$error : null
        },
        validateStateSingle(key) {
            const { $dirty, $error } = this.$v[key]
            return $dirty ? !$error : null
        },
        validateStateDevolucion(key) {
            const { $dirty, $error } = this.$v.devolucion_factura[key]
            return $dirty ? !$error : null
        },
        validateStateProducts(index, key) {
            const { $dirty, $error } =
                this.$v.factura_venta_productosCopy.$each[index][key]
            return $dirty ? !$error : null
        },
        validateStateFormasPago(index, key) {
            const { $dirty, $error } =
                this.$v.factura_venta.formas_pago.$each[index][key]
            return $dirty ? !$error : null
        },
        openItemsTable() {
            this.$refs.itemsButton.click()
        }
    },
    computed: {
        granTotal() {
            const {
                subtotal_neto: subtotal,
                total_ipoconsumo: impoconsumo,
                total_iva: iva,
                descuento
            } = this.totalesFacturas

            const total =
                subtotal +
                iva +
                impoconsumo -
                (descuento + this.total_retefuente + this.total_reteiva)

            return total
        },
        totalesFacturas() {
            const fields = [
                'total',
                'descuento',
                'total_ipoconsumo',
                'total_iva',
                'subtotal_neto'
            ]
            const productos = this.factura_venta.productos
            const totales = {}

            fields.forEach((field) => {
                const arrayTotal = productos.map((producto) => producto[field])

                const total = arrayTotal.reduce((a, b) => Math.round(a + b), 0)

                totales[field] = total
            })

            return totales
        },
        formasPagoValue() {
            // if (this.$v.plazo_vencimiento.$model === 0) {
            //     return this.devolucion_factura.formas_pago
            //         .map((f) => f.value)
            //         .reduce((a, b) => Number(a) + Number(b), 0)
            // } else {
            //     return 0
            // }
            return 0
        },
        cambio() {
            if (this.formasPagoValue > this.granTotal) {
                const value = this.formasPagoValue - this.granTotal
                return value
            } else {
                return 0
            }
        },
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back,
                    'command+b': this.read_only ? () => {} : this.openItemsTable
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back,
                    'ctrl+b': this.read_only ? () => {} : this.openItemsTable
                }
            } else {
                return {}
            }
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 46 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson', 'getPermisos']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede'])
    }
}
</script>

<style></style>
