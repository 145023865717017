<template>
    <b-modal
        id="create-tarifa-iva-modal"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Crear tercero"
        @shown="openModal"
        @close="resetFom"
    >
        <b-card class="mb-4 w-100">
            <div>
                <b-form class="p-5">
                    <div class="d-flex">
                        <div>
                            <h3 class="mb-2">Datos del iva</h3>
                            <div class="text-gray mb-5">
                                Por favor registre los datos del iva para
                                continuar
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols="12" md="3" lg="3" xl="3">
                            <b-form-group>
                                <label for="codigo">Código</label>
                                <b-form-input
                                    id="codigo"
                                    type="text"
                                    placeholder="Ej: 001"
                                    v-model="$v.iva.codigo.$model"
                                    :state="validateState('codigo')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.iva.codigo.$anyError"
                                >
                                    ({{ $v.iva.codigo.$model.length }} /
                                    {{ $v.iva.codigo.$params.maxLength.max }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.iva.codigo.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{ $v.iva.codigo.$params.maxLength.max }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.iva.codigo.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="9" lg="9" xl="9">
                            <b-form-group>
                                <label for="descripcion">Descripción</label>
                                <b-form-input
                                    id="descripcion"
                                    type="text"
                                    placeholder="Ej: Mi configuración de iva"
                                    v-model="$v.iva.descripcion.$model"
                                    :state="validateState('descripcion')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.iva.descripcion.$anyError"
                                >
                                    ({{ $v.iva.descripcion.$model.length }}
                                    /
                                    {{
                                        $v.iva.descripcion.$params.maxLength
                                            .max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.iva.descripcion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.iva.descripcion.$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.iva.descripcion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="porcentaje"
                                    >Porcentaje de iva</label
                                >
                                <b-form-input
                                    id="porcentaje"
                                    type="number"
                                    placeholder="Ej: 19"
                                    v-model="$v.iva.porcentaje.$model"
                                    :state="validateState('porcentaje')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.iva.porcentaje.$anyError"
                                >
                                    ({{ $v.iva.porcentaje.$model.length }} /
                                    {{
                                        $v.iva.porcentaje.$params.maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.iva.porcentaje.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.iva.porcentaje.$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.iva.porcentaje.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="8" lg="8" xl="8">
                            <b-form-group>
                                <label for="cuenta_venta"
                                    >Cuenta de ventas
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="cuenta_venta"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoCuenta = 'cuenta_venta'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="$v.iva.cuenta_venta.$model"
                                    :state="validateState('cuenta_venta')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="item.tipo === 'S'"
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.iva.cuenta_venta.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="cuenta_dev_venta"
                                    >Cuenta devolución en ventas
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="cuenta_dev_venta"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoCuenta = 'cuenta_dev_venta'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="$v.iva.cuenta_dev_venta.$model"
                                    :state="validateState('cuenta_dev_venta')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="item.tipo === 'S'"
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.iva.cuenta_dev_venta.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="cuenta_compra"
                                    >Cuenta de compras
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="cuenta_compra"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoCuenta = 'cuenta_compra'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="$v.iva.cuenta_compra.$model"
                                    :state="validateState('cuenta_compra')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="
                                                item.tipo === 'S' || read_only
                                            "
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.iva.cuenta_compra.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="cuenta_dev_compra"
                                    >Cuenta devolución en compras
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="cuenta_dev_compra"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoCuenta = 'cuenta_dev_compra'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="$v.iva.cuenta_dev_compra.$model"
                                    :state="validateState('cuenta_dev_compra')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="
                                                item.tipo === 'S' || read_only
                                            "
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.iva.cuenta_dev_compra.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <div class="float-right">
                                <div class="d-flex flex-row">
                                    <b-button
                                        variant="primary"
                                        :disabled="isLoading || read_only"
                                        size="md"
                                        class="d-flex align-items-center"
                                        v-b-popover.hover.top="
                                            'Presione ctrl+shift+s o cmd+shift+s'
                                        "
                                        @click="save"
                                    >
                                        <feather
                                            type="save"
                                            size="1rem"
                                            class="mr-2 text-blue-active"
                                        />
                                        <span
                                            v-if="isLoading"
                                            class="d-sm-flex align-items-center justify-content-center"
                                        >
                                            <b-spinner small></b-spinner>
                                            <span class="ml-2"
                                                >Guardando...</span
                                            >
                                        </span>
                                        <span v-else>Guardar</span>
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
            <crearPucModalVue @newPuc="setNewPuc" />
        </b-card>
    </b-modal>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import { filterPuc } from '@/utils/filters'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'
import crearPucModalVue from '../../../contabilidad/configuracion/puc/crearPucModal.vue'

const { API } = services

const defaultIva = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            porcentaje: '',
            cuenta_venta: null,
            cuenta_dev_venta: null,
            cuenta_compra: null,
            cuenta_dev_compra: null,
            empresa_id: null
        })
    )

export default {
    name: 'crearTarifaIvaModal',
    mixins: [validationMixin],
    components: { jautocomplete, crearPucModalVue },
    data: () => ({
        tipoCuenta: '',
        puc: [],
        ivaCopy: defaultIva(),
        iva: defaultIva(),
        isLoading: false,
        isSaved: true,
        read_only: false
    }),
    watch: {
        iva: {
            handler(newValue) {
                this.isSaved = compareObjects(this.ivaCopy, newValue)
            },
            deep: true
        }
    },
    async mounted() {
        addListenerCommands(this)
        await this.getParams()
        // this.getPuc()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        iva: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            porcentaje: {
                required,
                maxLength: maxLength(5)
            },
            cuenta_venta: {
                required
            },
            cuenta_dev_venta: {
                required
            },
            cuenta_compra: {
                required
            },
            cuenta_dev_compra: {
                required
            }
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        setNewPuc(pucItem) {
            this.puc.push(pucItem)
            this.iva[this.tipoCuenta] = pucItem
        },
        filterPuc,
        async getParams() {
            const { iva_id } = this.$route.params
            if (iva_id) {
                await this.getIvaById(iva_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        async getIvaById(id) {
            // console.log('acá 2');
            await API.POST({
                url: 'administracion/inventario/iva/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then(async (response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        await this.getPuc({
                            term: dato.cuenta_venta.descripcion
                        })
                        await this.getPuc({
                            term: dato.cuenta_dev_venta.descripcion
                        })
                        await this.getPuc({
                            term: dato.cuenta_compra.descripcion
                        })
                        await this.getPuc({
                            term: dato.cuenta_dev_compra.descripcion
                        })

                        this.ivaCopy = { ...dato }
                        this.iva = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        filterPucData(text) {
            if (text && text.length > 3) {
                this.getPuc({ term: text })
            }
        },
        async getPuc({ term }) {
            await API.POST({
                url: 'contabilidad/puc/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.puc = dato.content
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.iva.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.iva.id ? 'UPDATE' : 'INSERT'
                this.iva.empresa_id = this.getUltimaEmpresa.id
                API.POST({
                    url: 'administracion/inventario/iva/crud',
                    data: {
                        p_datajson: {
                            ...this.iva
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.back()
                            this.$emit('newTarifaIva', dato)
                            this.resetFom()
                            this.close()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.iva.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.iva[key]
            return $dirty ? !$error : null
        },
        openModal() {
            this.goToNextField('codigo')
        },
        resetFom() {
            this.iva = defaultIva()
            this.$v.iva.$reset()
        },
        close() {
            this.$bvModal.hide('create-tarifa-iva-modal')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 13 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
