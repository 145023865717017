<template>
    <b-modal
        id="modal-items"
        header-bg-variant="primary"
        hide-footer
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Items"
        @show="
            getLotesInventarios(),
                getBodegas(),
                getSaldosInventario(1, pagination.perPage)
        "
        @hidden="items = []"
    >
        <b-card class="p-3">
            <b-row class="mt-5 mb-4">
                <b-col cols="12" sm="6">
                    <label for="bodega_id">Bodega</label>
                    <jautocomplete
                        id="bodega_id"
                        :items="bodegas"
                        item-value="id"
                        item-text="descripcion"
                        open-on-focus
                        v-model="$v.form.bodega_id.$model"
                    >
                        <template v-slot:option="{ item }">
                            <span
                                >{{ item.codigo }} -
                                {{ item.descripcion }}</span
                            >
                        </template>
                    </jautocomplete>
                </b-col>

                <b-col cols="12" sm="6">
                    <label for="lote_inventario_id">Lote inventario</label>
                    <jautocomplete
                        id="lote_inventario_id"
                        :items="lotes_inventario"
                        item-value="id"
                        item-text="nombre"
                        open-on-focus
                        v-model="$v.form.lote_inventario_id.$model"
                    >
                        <template v-slot:option="{ item }">
                            <span>{{ item.codigo }} - {{ item.nombre }}</span>
                        </template>
                    </jautocomplete>
                </b-col>
            </b-row>
            <b-input-group>
                <b-form-input
                    id="filter-input"
                    type="text"
                    placeholder="Buscar registros"
                    class="bg-background-input"
                    v-model="search"
                    @keydown.enter="searchData"
                    @input="search === '' && searchData()"
                ></b-form-input>

                <b-input-group-append>
                    <b-button
                        variant="background-input"
                        class="text-blue-active d-flex align-items-center"
                        @click="searchData()"
                    >
                        <feather type="search" size="1.1rem"></feather>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <div class="table-responsive mt-5">
                <b-table
                    :fields="fields"
                    :items="saldos_inventario"
                    :tbody-tr-class="rowClass"
                    borderless
                    responsive
                    head-variant="light"
                    tbody-class="cursor-pointer"
                    class="mb-0 hover-table text-nowrap"
                    @row-clicked="setItem"
                >
                    <template #cell(referencia)="data">
                        <span
                            :class="`badge bg-light-success ${
                                Theme === 'dark' && 'text-gray'
                            }`"
                        >
                            {{ data.item.item.referencia }}
                        </span>
                    </template>
                    <template #cell(descripcion)="data">
                        <span>
                            {{ data.item.item.descripcion }}
                        </span>
                    </template>
                    <template #cell(codigo_barra)="data">
                        <span>
                            {{ data.item.item.codigo_barra }}
                        </span>
                    </template>
                    <template #cell(saldo)="data">
                        <span>
                            {{ data.item.saldo }}
                        </span>
                    </template>
                    <template #cell(precio_base_venta)="data">
                        <span>
                            {{
                                currencyFormat(data.item.item.precio_base_venta)
                            }}
                        </span>
                    </template>
                    <template #cell(cliente)="data">
                        <span>
                            {{ data.value.nombre_comercial }}
                        </span>
                    </template>
                    <template #cell(cliente_id)="data">
                        <span>
                            {{ data.value }}
                        </span>
                    </template>
                </b-table>
            </div>
            <div class="p-3 d-flex align-items-center">
                <b-form-group
                    label="Número de registros"
                    label-for="per-page-select"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="pagination.perPage"
                        :options="pagination.pageOptions"
                        @change="getSaldosInventario(1, $event)"
                        size="sm"
                    ></b-form-select>
                </b-form-group>
                <div class="ml-auto">
                    <b-pagination
                        first-number
                        last-number
                        size="sm"
                        v-model="pagination.currentPage"
                        :total-rows="pagination.totalRows"
                        :per-page="pagination.perPage"
                        @input="getSaldosInventario($event, pagination.perPage)"
                        align="fill"
                        class="my-0"
                    ></b-pagination>
                </div>
            </div>
        </b-card>
    </b-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import services from '@/boot/axios'
import { currencyFormat } from '@/utils/others'
import { validationMixin } from 'vuelidate'
import Jautocomplete from '@/components/shared/autocomplete/jautocomplete.vue'

const { API } = services

const formDefault = () =>
    JSON.parse(
        JSON.stringify({
            lote_inventario_id: null,
            bodega_id: null
        })
    )

export default {
    name: 'ModalItems',
    components: { Jautocomplete },
    mixins: [validationMixin],
    data: () => ({
        form: formDefault(),
        saldos_inventario: [],
        search: '',
        lotes_inventario: [],
        bodegas: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        fields: [
            { key: 'referencia', label: 'Referencia' },
            { key: 'descripcion', label: 'Nombre producto' },
            { key: 'codigo_barra', label: 'Código de barras' },
            { key: 'saldo', label: 'Saldo de inventario' },
            { key: 'precio_base_venta', label: 'Precio Venta' }
        ]
    }),
    mounted() {
        this.form.bodega_id = this.getUltimaSede.bodega
            ? this.getUltimaSede.bodega.id
            : null
    },
    validations: {
        form: {
            lote_inventario_id: {},
            bodega_id: {}
        }
    },
    methods: {
        currencyFormat,
        showLog(data) {
            console.log(data)
        },
        setItem({ lote_inventario_id, bodega_id, item }) {
            // console.log(item)
            this.$emit('selectedItem', {
                ...item,
                bodega_id,
                lote_inventario_id
            })
            this.close()
        },
        searchData() {
            this.getSaldosInventario()
        },
        getSaldosInventario(page = 1, limit = this.pagination.perPage) {
            const { bodega_id, lote_inventario_id } = this.form
            API.POST({
                url: 'administracion/inventario/inventario-saldo/query',
                data: {
                    p_datajson: {
                        page,
                        term: this.search,
                        limit,
                        bodega_id,
                        lote_inventario_id,
                        // año: ano,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.saldos_inventario = content
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size,
                            currentPage: number
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getLotesInventarios() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(response)
                    if (status === 'ok') {
                        this.lotes_inventario = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                        // console.log(this.bodegas)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.form[key]
            return $dirty ? !$error : null
        },
        close() {
            this.$bvModal.hide('modal-items')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 15 }
            return json
        },
        ...mapState(['Theme']),
        ...mapGetters('usuario', ['getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
