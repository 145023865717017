<template>
    <b-modal
        id="create-establecimiento-comercio-modal"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Crear tercero"
        @shown="openModal"
        @close="resetFom"
    >
        <b-card class="mb-4 w-100">
            <div>
                <b-form class="p-5">
                    <div class="d-flex">
                        <div>
                            <h3 class="mb-2">
                                Datos del establecimiento de comercio
                            </h3>
                            <div class="text-gray mb-5">
                                Por favor registre los datos del establecimiento
                                de comercio para continuar
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols="12" md="3" lg="3" xl="3">
                            <b-form-group>
                                <label for="codigo">Código</label>
                                <b-form-input
                                    id="codigo"
                                    type="text"
                                    placeholder="Ej: 001"
                                    v-model="$v.establecimiento.codigo.$model"
                                    :state="validateState('codigo')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.establecimiento.codigo.$anyError"
                                >
                                    ({{
                                        $v.establecimiento.codigo.$model.length
                                    }}
                                    /
                                    {{
                                        $v.establecimiento.codigo.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.establecimiento.codigo.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.establecimiento.codigo.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.establecimiento.codigo.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="tercero_id">Tercero</label>
                                <jautocomplete
                                    id="tercero_id"
                                    :items="tercerosCopy"
                                    item-value="id"
                                    item-text="n_documento"
                                    :custom-filter="filterTerceros"
                                    open-on-focus
                                    @change="showLoadTercero"
                                    :custom-text-field="textFieldTercero"
                                    v-model="
                                        $v.establecimiento.tercero_id.$model
                                    "
                                    show-create-button
                                    :create-method="
                                        () => {
                                            $bvModal.show(
                                                'create-tercero-modal'
                                            )
                                        }
                                    "
                                    :state="validateState('tercero_id')"
                                    :disabled="read_only"
                                >
                                    <template v-slot:option="{ item }">
                                        <div class="p-1 option" role="button">
                                            {{
                                                item.tipo_documento_id === '31'
                                                    ? item.razon_social
                                                    : `${item.nombre1} ${item.apellido1}`
                                            }}
                                        </div>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.establecimiento.tercero_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5" lg="5" xl="5">
                            <b-form-group>
                                <label for="nombre_comercial"
                                    >Nombre comercial</label
                                >
                                <b-form-input
                                    id="nombre_comercial"
                                    type="text"
                                    placeholder="Nombre comercial"
                                    v-model="
                                        $v.establecimiento.nombre_comercial
                                            .$model
                                    "
                                    :state="validateState('nombre_comercial')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="
                                        !$v.establecimiento.nombre_comercial
                                            .$anyError
                                    "
                                >
                                    ({{
                                        $v.establecimiento.nombre_comercial
                                            .$model.length
                                    }}
                                    /
                                    {{
                                        $v.establecimiento.nombre_comercial
                                            .$params.maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.establecimiento.nombre_comercial
                                            .maxLength
                                    "
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.establecimiento.nombre_comercial
                                            .$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.establecimiento.nombre_comercial
                                            .required
                                    "
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="direccion"
                                    >Dirección
                                    <small style="color: red">*</small></label
                                >
                                <b-form-input
                                    autocomplete="null"
                                    id="direccion"
                                    type="text"
                                    placeholder="Ej: calle 27 #38-43"
                                    v-model="
                                        $v.establecimiento.direccion.$model
                                    "
                                    :state="validateState('direccion')"
                                    :disabled="read_only"
                                ></b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="
                                        !$v.establecimiento.direccion.$anyError
                                    "
                                >
                                    ({{
                                        $v.establecimiento.direccion.$model
                                            .length
                                    }}
                                    /
                                    {{
                                        $v.establecimiento.direccion.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.establecimiento.direccion.maxLength
                                    "
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.establecimiento.direccion.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.establecimiento.direccion.required
                                    "
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="telefono">Teléfono</label>
                                <b-form-input
                                    id="telefono"
                                    type="number"
                                    placeholder="Ej: 7852532"
                                    v-model="$v.establecimiento.telefono.$model"
                                    :state="validateState('telefono')"
                                    :disabled="read_only"
                                ></b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="
                                        !$v.establecimiento.telefono.$anyError
                                    "
                                >
                                    ({{
                                        $v.establecimiento.telefono.$model
                                            .length
                                    }}
                                    /
                                    {{
                                        $v.establecimiento.telefono.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.establecimiento.telefono.maxLength
                                    "
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.establecimiento.telefono.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="pais_id"
                                    >País
                                    <small style="color: red">*</small></label
                                >
                                <!-- <b-form-select
                                    id="pais_id"
                                    text-field="descripcion"
                                    value-field="id"
                                    :options="paises"
                                    v-model="$v.establecimiento.pais_id.$model"
                                    @change="
                                        ;(municipios = []),
                                            (establecimiento.municipio_id =
                                                null),
                                            getMunicipios($event)
                                    "
                                    :state="validateState('pais_id')"
                                    :disabled="read_only"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Seleccione</b-form-select-option
                                        >
                                    </template>
                                </b-form-select> -->
                                <jautocomplete
                                    id="pais_id"
                                    :items="paises"
                                    item-text="descripcion"
                                    item-value="id"
                                    open-on-focus
                                    v-model="$v.establecimiento.pais_id.$model"
                                    :state="validateState('pais_id')"
                                    :disabled="read_only"
                                    @change="
                                        ;(municipios = []),
                                            (establecimiento.municipio_id =
                                                null),
                                            getMunicipios($event)
                                    "
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.establecimiento.pais_id.$error"
                                >
                                    Campo requerido
                                </div>
                                <!-- <b-form-invalid-feedback
                                    v-if="!$v.establecimiento.pais_id.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="municipio_id"
                                    >Ciudad
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="municipio_id"
                                    :items="municipios"
                                    item-text="descripcion"
                                    item-value="id"
                                    open-on-focus
                                    :disabled="
                                        municipios.length === 0 || read_only
                                    "
                                    v-model="
                                        $v.establecimiento.municipio_id.$model
                                    "
                                    :state="validateState('municipio_id')"
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="
                                        $v.establecimiento.municipio_id.$error
                                    "
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="email"
                                    >E-mail
                                    <small style="color: red">*</small></label
                                >
                                <b-form-input
                                    id="email"
                                    type="text"
                                    placeholder="Ej: correo@ejemplo.com"
                                    v-model="$v.establecimiento.email.$model"
                                    :state="validateState('email')"
                                    :disabled="read_only"
                                ></b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.establecimiento.email.$anyError"
                                >
                                    ({{
                                        $v.establecimiento.email.$model.length
                                    }}
                                    /
                                    {{
                                        $v.establecimiento.email.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.establecimiento.email.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.establecimiento.email.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.establecimiento.email.email"
                                >
                                    E-mail inválido
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.establecimiento.email.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="zona_id"
                                    >Zona
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="zona_id"
                                    :items="zonas"
                                    item-text="descripcion"
                                    item-value="id"
                                    open-on-focus
                                    :disabled="zonas.length === 0 || read_only"
                                    v-model="$v.establecimiento.zona_id.$model"
                                    show-create-button
                                    :create-method="
                                        () => {
                                            $bvModal.show('create-zona-modal')
                                        }
                                    "
                                    :state="validateState('zona_id')"
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.establecimiento.zona_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3" lg="3" xl="3">
                            <b-form-group>
                                <label for="plazo_credito"
                                    >Plazo crédito<small style="color: red"
                                        >*</small
                                    ></label
                                >
                                <b-form-input
                                    id="plazo_credito"
                                    type="number"
                                    placeholder="Ej: 30"
                                    v-model="
                                        $v.establecimiento.plazo_credito.$model
                                    "
                                    :state="validateState('plazo_credito')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="
                                        !$v.establecimiento.plazo_credito
                                            .$anyError
                                    "
                                >
                                    ({{
                                        $v.establecimiento.plazo_credito.$model
                                            .length
                                    }}
                                    /
                                    {{
                                        $v.establecimiento.plazo_credito.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.establecimiento.plazo_credito
                                            .maxLength
                                    "
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.establecimiento.plazo_credito.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.establecimiento.plazo_credito
                                            .required
                                    "
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5" lg="5" xl="5">
                            <b-form-group>
                                <label for="puc_ventas"
                                    >Puc ventas<small style="color: red"
                                        >*</small
                                    ></label
                                >
                                <jautocomplete
                                    id="puc_ventas"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="
                                        $v.establecimiento.puc_ventas.$model
                                    "
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoPuc = 'puc_ventas'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    :state="validateState('puc_ventas')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="
                                                item.tipo === 'S' || read_only
                                            "
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.establecimiento.puc_ventas.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="puc_compras"
                                    >Puc compras<small style="color: red"
                                        >*</small
                                    ></label
                                >
                                <jautocomplete
                                    id="puc_compras"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="
                                        $v.establecimiento.puc_compras.$model
                                    "
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoPuc = 'puc_compras'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    :state="validateState('puc_compras')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="item.tipo === 'S'"
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.establecimiento.puc_compras.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="vendedores"
                                    >Vendedor(es)<small style="color: red"
                                        >*</small
                                    ></label
                                >
                                <jautocomplete
                                    id="vendedores"
                                    :items="vendedores"
                                    item-text="descripcion"
                                    item-value="id"
                                    multiple
                                    open-on-focus
                                    :disabled="
                                        vendedores.length === 0 || read_only
                                    "
                                    v-model="
                                        $v.establecimiento.vendedores.$model
                                    "
                                    show-create-button
                                    :create-method="
                                        () => {
                                            $bvModal.show(
                                                'create-vendedor-modal'
                                            )
                                        }
                                    "
                                    :state="validateState('vendedores')"
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.establecimiento.vendedores.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <div class="float-right">
                                <div class="d-flex flex-row">
                                    <b-button
                                        variant="primary"
                                        :disabled="isLoading || read_only"
                                        size="md"
                                        class="d-flex align-items-center"
                                        v-b-popover.hover.top="
                                            'Presione ctrl+shift+s o cmd+shift+s'
                                        "
                                        @click="save"
                                    >
                                        <feather
                                            type="save"
                                            size="1rem"
                                            class="mr-2 text-blue-active"
                                        />
                                        <span
                                            v-if="isLoading"
                                            class="d-sm-flex align-items-center justify-content-center"
                                        >
                                            <b-spinner small></b-spinner>
                                            <span class="ml-2"
                                                >Guardando...</span
                                            >
                                        </span>
                                        <span v-else>Guardar</span>
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-card>
        <crearTerceroModalVue @newTercero="setNewTercero" />
        <crearZonaModalVue @newZona="setNewZona" />
        <crearPucModalVue @newPuc="setNewPuc" />
        <crearVendedoresModalVue @newVendedor="setNewVendedor" />
    </b-modal>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import {
    required,
    maxLength,
    email,
    requiredUnless
} from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'
import { filterPuc, filterTerceros } from '@/utils/filters'
import crearTerceroModalVue from '../../../general/generales/terceros/crearTerceroModal.vue'
import crearZonaModalVue from '../zonas/crearZonaModal.vue'
import crearPucModalVue from '../../../contabilidad/configuracion/puc/crearPucModal.vue'
import crearVendedoresModalVue from '../vendedores/crearVendedoresModal.vue'

const { API } = services

const defaultEstablecimiento = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            nombre_comercial: '',
            tercero_id: null,
            empresa_id: null,
            direccion: '',
            email: '',
            zona_id: null,
            plazo_credito: '',
            municipio_id: null,
            pais_id: null,
            puc_ventas: null,
            puc_compras: null,
            telefono: '',
            vendedores: []
        })
    )

export default {
    name: 'CrearEstablecimientoComercio',
    mixins: [validationMixin],
    components: {
        jautocomplete,
        crearTerceroModalVue,
        crearZonaModalVue,
        crearPucModalVue,
        crearVendedoresModalVue
    },
    props: {
        n_documento: {
            type: String
        }
    },
    data: () => ({
        tipoPuc: '',
        terceros: [],
        paises: [],
        tercerosCopy: [],
        municipios: [],
        zonas: [],
        vendedores: [],
        puc: [],
        establecimientoCopy: defaultEstablecimiento(),
        establecimiento: defaultEstablecimiento(),
        isLoading: false,
        isSaved: true,
        read_only: false
    }),
    watch: {
        establecimiento: {
            handler(newValue) {
                this.isSaved = compareObjects(
                    this.establecimientoCopy,
                    newValue
                )
            },
            deep: true
        }
    },
    async mounted() {
        addListenerCommands(this)
        this.getTerceros()
        // this.getPuc()
        this.getZonas()
        this.getVendedores()
        this.getPaises()
        await this.getParams()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (!this.getUserData) {
            next()
            return
        }
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        establecimiento: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            nombre_comercial: {
                required,
                maxLength: maxLength(150)
            },
            tercero_id: {
                required
            },
            direccion: {
                required,
                maxLength: maxLength(150)
            },
            municipio_id: {
                required
            },
            pais_id: {
                required
            },
            email: {
                required,
                maxLength: maxLength(150),
                email
            },
            zona_id: {
                required
            },
            plazo_credito: {
                required,
                maxLength: maxLength(4)
            },
            puc_ventas: {
                required
            },
            puc_compras: {
                required
            },
            telefono: {
                maxLength: maxLength(15)
            },
            vendedores: {
                requiredUnless: requiredUnless((establecimiento) => {
                    return establecimiento.vendedores.length > 0
                })
            }
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        setNewTercero(tercero) {
            this.terceros.push(tercero)
            this.establecimiento.tercero_id = tercero.id
        },
        setNewZona(zona) {
            this.zonas.push(zona)
            this.establecimiento.zona_id = zona.id
        },
        setNewPuc(pucItem) {
            this.puc.push(pucItem)
            this.establecimiento[this.tipoPuc] = pucItem
        },
        setNewVendedor(vendedor) {
            this.vendedores.push(vendedor)
            this.establecimiento.vendedores.push(vendedor.id)
        },
        filterPuc,
        filterTerceros,
        filterPucData(text) {
            if (text && text.length > 3) {
                this.getPuc({ term: text })
            }
        },
        async getPuc({ term }) {
            await API.POST({
                url: 'contabilidad/puc/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.puc = dato.content
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        textFieldTercero(tercero) {
            return tercero.tipo_documento_id === '31'
                ? tercero.razon_social
                : `${tercero.nombre1} ${tercero.apellido1}`
        },
        async getParams() {
            const { establecimiento_comercio_id, tercero } = this.$route.params
            if (establecimiento_comercio_id) {
                await this.getEstablecimientoById(establecimiento_comercio_id)
            }

            if (tercero) {
                this.establecimiento.tercero_id = tercero.id
                this.loadTerceroData(tercero)
            }

            this.read_only = this.$route.meta.read_only
        },
        getPaises() {
            API.POST({
                url: 'general/pais/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.paises = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getVendedores() {
            API.POST({
                url: 'administracion/vendedor/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.vendedores = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getZonas() {
            API.POST({
                url: 'administracion/zona/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.zonas = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getMunicipios(pais_id) {
            API.POST({
                url: 'general/municipio/query',
                data: {
                    p_datajson: {
                        pais_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.municipios = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        async getEstablecimientoById(id) {
            // console.log('acá 2');
            await API.POST({
                url: 'general/establecimiento-comercio/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then(async (response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        await this.getPuc({
                            term: dato.puc_compras.descripcion
                        })
                        await this.getPuc({ term: dato.puc_ventas.descripcion })

                        this.getMunicipios(dato.pais_id)

                        this.establecimientoCopy = { ...dato }
                        this.establecimiento = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTerceros() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'general/tercero/query',
                    data: {
                        p_datajson: {
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.terceros = dato
                            this.tercerosCopy = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        save() {
            const valid = !this.$v.establecimiento.$invalid
            // console.log(this.establecimiento)
            if (valid) {
                this.isLoading = true
                const p_opc = this.establecimiento.id ? 'UPDATE' : 'INSERT'
                this.establecimiento.empresa_id = this.getUltimaEmpresa.id
                API.POST({
                    url: 'general/establecimiento-comercio/crud',
                    data: {
                        p_datajson: {
                            ...this.establecimiento
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            // this.back()
                            this.$emit('newEstablecimientoComercio', dato)
                            this.resetFom()
                            this.close()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.establecimiento.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.establecimiento[key]
            return $dirty ? !$error : null
        },
        showLoadTercero(tercero_id) {
            if (tercero_id) {
                this.$bvModal
                    .msgBoxConfirm(
                        `
            Desea cargar la información de este tercero para el establecimiento de comercio?`,
                        {
                            title: 'Advertencia!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: 'Continuar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2 d-flex justify-content-between',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'danger',
                            headerClass: 'modal-header'
                        }
                    )
                    .then((value) => {
                        if (value) {
                            const tercero = this.terceros.find(
                                (t) => t.id === tercero_id
                            )
                            this.loadTerceroData(tercero)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        loadTerceroData(tercero) {
            const {
                pais_id,
                municipio_id,
                email,
                direccion,
                nombre1,
                nombre2,
                apellido1,
                apellido2,
                telefono,
                celular
            } = tercero

            const nombre_comercial = `${nombre1}${
                nombre2 && ` ${nombre2}`
            } ${apellido1}${apellido2 && ` ${apellido2}`}`
            const telefono_establecimiento = telefono ? telefono : celular

            this.getMunicipios(pais_id)

            this.establecimiento = {
                ...this.establecimiento,
                pais_id,
                municipio_id,
                email,
                direccion,
                nombre_comercial,
                telefono: telefono_establecimiento
            }
        },
        openModal() {
            this.goToNextField('codigo')
        },
        resetFom() {
            this.establecimiento = defaultEstablecimiento()
            this.$v.establecimiento.$reset()
        },
        close() {
            this.$bvModal.hide('create-establecimiento-comercio-modal')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 18 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
