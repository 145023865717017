const obtenerRetencionFactura = (retencionBaseData) => {
    const {
        tercero: { agente_retencion_fuente, regimen_simple_tributacion },
        empresa: { agente_retencion_fuente: arfEmpresa, autoretenedor_fuente }
    } = retencionBaseData

    const hacer_retencion_tercero =
        agente_retencion_fuente || regimen_simple_tributacion

    // console.log(
    //     'agente_retencion_fuente',
    //     agente_retencion_fuente,
    //     'regimen_simple_tributacion',
    //     regimen_simple_tributacion,
    //     'arfEmpresa',
    //     arfEmpresa,
    //     'autoretenedor_fuente',
    //     autoretenedor_fuente
    // )

    const hacer_retencion_factura =
        arfEmpresa && hacer_retencion_tercero && !autoretenedor_fuente

    // console.log(hacer_retencion_factura)

    return hacer_retencion_factura
}

const obtenerGruposYRetenciones = (productos, declarante) => {
    const gruposRetencion = {}
    const retenciones = {}

    productos
        .filter((p) => p.item_id !== null && p.item_id !== undefined)
        .forEach((producto) => {
            const retencion = producto.retencion
            const porcentajeRetencion = declarante
                ? retencion.porcentaje_declarante
                : retencion.porcentaje_no_declarante
            const llaveGrupo = porcentajeRetencion.toString()
            const retencionDefinida = retenciones[llaveGrupo]

            !retencionDefinida && (retenciones[llaveGrupo] = retencion)

            const descuento_producto = producto.descuento
            const total_producto = producto.total

            const subtotal_producto = total_producto - descuento_producto
            const valorGrupo = gruposRetencion[llaveGrupo]
            const llaveGrupoDefnida =
                valorGrupo !== undefined && valorGrupo !== null

            llaveGrupoDefnida
                ? (gruposRetencion[llaveGrupo] += subtotal_producto)
                : (gruposRetencion[llaveGrupo] = subtotal_producto)
        })

    return { gruposRetencion, retenciones }
}

const compararRetencionVsBaseRetencion = (gruposRetencion, retenciones) => {
    const llavesRetenciones = Object.keys(gruposRetencion)

    const comparacionesRetencionVsBaseRetencion = llavesRetenciones.map(
        (llave) => {
            const valorGrupo = gruposRetencion[llave]
            const baseRetencion = retenciones[llave].base_retencion
            return valorGrupo >= baseRetencion
        }
    )

    return comparacionesRetencionVsBaseRetencion
}

const hacerRetencion = (comparacionesRetencionVsBaseRetencion) => {
    return comparacionesRetencionVsBaseRetencion.some((value) => value === true)
}

const obtenerTotalRetencion = (hacerRetencion, gruposRetencion) => {
    let total_retencion = 0

    if (hacerRetencion) {
        const retenciones_grupo = Object.keys(gruposRetencion).map((llave) => {
            const valorGrupo = gruposRetencion[llave]
            const porcentaje_retencion = Number(llave)
            return (valorGrupo * porcentaje_retencion) / 100
        })

        total_retencion = retenciones_grupo.reduce((a, b) => a + b, 0)
    }

    return total_retencion
}

export const calcRetefuente = (productos, retencionBaseData) => {
    let total_retefuente = 0
    let total_reteiva = 0
    let retencion_detail = { gruposRetencion: {}, retenciones: {} }
    let reteiva_detail = { gruposReteIva: {} }
    const {
        tercero: { declarante }
    } = retencionBaseData

    const hacer_retencion_factura = obtenerRetencionFactura(retencionBaseData)

    // console.log(hacer_retencion_factura, retencionBaseData)

    if (hacer_retencion_factura) {
        const reteiva_data = calcReteIva(retencionBaseData, productos)
        // console.log('reteiva_data', reteiva_data)
        total_reteiva = reteiva_data.reteiva
        reteiva_detail =
            Object.keys(reteiva_data.reteiva_detail).length > 0
                ? reteiva_data.reteiva_detail
                : reteiva_detail

        const { gruposRetencion, retenciones } = obtenerGruposYRetenciones(
            productos,
            declarante
        )

        const comparacion = compararRetencionVsBaseRetencion(
            gruposRetencion,
            retenciones
        )

        const retencion = hacerRetencion(comparacion)

        if (retencion) {
            retencion_detail = { gruposRetencion, retenciones }
        }

        total_retefuente = obtenerTotalRetencion(retencion, gruposRetencion)

        total_retefuente = Math.round(total_retefuente)
    }

    return { total_retefuente, total_reteiva, reteiva_detail, retencion_detail }
}

const obtenerReteIvaFactura = (reteIvaBaseData) => {
    const {
        tercero: { gran_contribuyente },
        empresa: { agente_retencion_iva, gran_contribuyente: gcEmpresa }
    } = reteIvaBaseData

    // console.log(
    //     'agente_retencion_iva',
    //     agente_retencion_iva,
    //     'gran_contribuyente',
    //     gran_contribuyente,
    //     'gcEmpresa',
    //     gcEmpresa
    // )

    // console.log(agente_retencion_iva && gran_contribuyente && !gcEmpresa)

    return agente_retencion_iva && gran_contribuyente && !gcEmpresa
}

const obtenerGruposYReteIvas = (productos) => {
    const gruposReteIva = {}
    let porcentaje = ''

    const array_total_iva_productos = productos.map(
        (producto) => producto.total_iva
    )
    const total_iva = array_total_iva_productos.reduce((a, b) => a + b, 0)

    productos
        .filter((p) => p.item_id !== null && p.item_id !== undefined)
        .forEach((producto) => {
            const retencion = producto.retencion
            const porcentaje_reteiva = retencion.porcentaje_reteiva
            if (!porcentaje) {
                porcentaje = porcentaje_reteiva
            }
            const llaveGrupo = porcentaje_reteiva.toString()
            const total_reteiva_producto =
                (porcentaje_reteiva * total_iva) / 100
            const valorGrupo = gruposReteIva[llaveGrupo]

            const llaveGrupoDefnida =
                valorGrupo !== undefined && valorGrupo !== null

            llaveGrupoDefnida
                ? (gruposReteIva[llaveGrupo] += total_reteiva_producto)
                : (gruposReteIva[llaveGrupo] = total_reteiva_producto)
        })

    return { gruposReteIva, porcentaje }
}

export const calcReteIva = (reteIvaBaseData, productos) => {
    let total_reteIva = 0
    let reteiva_detail = {}

    const hacerReteIva = obtenerReteIvaFactura(reteIvaBaseData)

    // console.log(reteIvaBaseData)

    if (hacerReteIva) {
        const { gruposReteIva, porcentaje: porcentajeReteIva } =
            obtenerGruposYReteIvas(productos)

        reteiva_detail = { gruposReteIva, porcentajeReteIva }

        // console.log(gruposReteIva)

        const valores_reteiva_grupo = Object.keys(gruposReteIva).map(
            (llave) => gruposReteIva[llave]
        )

        total_reteIva = valores_reteiva_grupo.reduce((a, b) => a + b, 0)

        // console.log(total_reteIva)
    }

    return { reteiva: Math.round(total_reteIva), reteiva_detail }
}
