<template>
    <b-modal
        id="create-subcategoria-modal"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        @shown="openModal"
        title="Crear subcategoría"
    >
        <b-card class="p-3">
            <b-form class="p-5">
                <div class="d-flex">
                    <div>
                        <h3 class="mb-2">Datos de la subcategoría</h3>
                        <div class="text-gray mb-5">
                            Por favor registre los datos de la subcategoría para
                            continuar
                        </div>
                    </div>
                </div>
                <b-row>
                    <b-col cols="12" md="3" lg="3" xl="3">
                        <b-form-group>
                            <label for="codigo_subcat">Código</label>
                            <b-form-input
                                id="codigo_subcat"
                                type="text"
                                placeholder="Ej: 001"
                                v-model="$v.subcategoria.codigo.$model"
                                :state="validateState('codigo')"
                            >
                            </b-form-input>
                            <div
                                class="length-field mt-1"
                                v-if="!$v.subcategoria.codigo.$anyError"
                            >
                                ({{ $v.subcategoria.codigo.$model.length }}
                                /
                                {{
                                    $v.subcategoria.codigo.$params.maxLength
                                        .max
                                }})
                            </div>
                            <b-form-invalid-feedback
                                v-if="!$v.subcategoria.codigo.maxLength"
                            >
                                Este campo debe tener máximo
                                {{
                                    $v.subcategoria.codigo.$params.maxLength.max
                                }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.subcategoria.codigo.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="9" lg="9" xl="9">
                        <b-form-group>
                            <label for="descripcion">Descripción</label>
                            <b-form-input
                                id="descripcion"
                                type="text"
                                placeholder="Ej: Mi subcategoría"
                                v-model="$v.subcategoria.descripcion.$model"
                                :state="validateState('descripcion')"
                            >
                            </b-form-input>
                            <div
                                class="length-field mt-1"
                                v-if="!$v.subcategoria.descripcion.$anyError"
                            >
                                ({{ $v.subcategoria.descripcion.$model.length }}
                                /
                                {{
                                    $v.subcategoria.descripcion.$params
                                        .maxLength.max
                                }})
                            </div>
                            <b-form-invalid-feedback
                                v-if="!$v.subcategoria.descripcion.maxLength"
                            >
                                Este campo debe tener máximo
                                {{
                                    $v.subcategoria.descripcion.$params
                                        .maxLength.max
                                }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.subcategoria.descripcion.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mt-md-4">
                    <b-col cols="12" md="12" lg="12" xl="12">
                        <b-form-group>
                            <label for="categoria_id"
                                >Categoria
                                <small style="color: red">*</small></label
                            >
                            <jautocomplete
                                id="categoria_id"
                                :items="categorias"
                                item-text="descripcion"
                                item-value="id"
                                open-on-focus
                                :disabled="categorias.length === 0"
                                v-model="$v.subcategoria.categoria_id.$model"
                                :state="validateState('categoria_id')"
                            />
                            <div
                                class="invalid-field mt-1"
                                v-if="$v.subcategoria.categoria_id.$error"
                            >
                                Campo requerido
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="float-right d-flex flex-row mt-4">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="close"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </b-form>
        </b-card>
    </b-modal>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'

const { API } = services

const defaultSubcategoria = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            categoria_id: null,
            empresa_id: null
        })
    )

export default {
    name: 'CrearSubcategoriaModal',
    mixins: [validationMixin],
    components: { jautocomplete },
    data: () => ({
        puc: [],
        subcategoriaCopy: defaultSubcategoria(),
        subcategoria: defaultSubcategoria(),
        categorias: [],
        isLoading: false,
        isSaved: true
    }),
    watch: {
        subcategoria: {
            handler(newValue) {
                this.isSaved = compareObjects(this.subcategoriaCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        addListenerCommands(this)
        this.getCategorias()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        subcategoria: {
            codigo: {
                required,
                maxLength: maxLength(20)
            },
            descripcion: {
                required,
                maxLength: maxLength(100)
            },
            categoria_id: {
                required
            }
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        getCategorias() {
            API.POST({
                url: 'administracion/inventario/categoria/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato);
                        this.categorias = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.subcategoria.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.subcategoria.id ? 'UPDATE' : 'INSERT'
                this.subcategoria.empresa_id = this.getUltimaEmpresa.id
                API.POST({
                    url: 'administracion/inventario/subcategoria/crud',
                    data: {
                        p_datajson: {
                            ...this.subcategoria
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            this.$emit('newSubcategoria', dato)
                            this.subcategoria = defaultSubcategoria()
                            this.$v.subcategoria.$reset()
                            this.close()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.subcategoria.$touch()
            }
        },
        close() {
            this.$bvModal.hide('create-subcategoria-modal')
        },
        openModal() {
            this.goToNextField('codigo_subcat')
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.subcategoria[key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 8 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
