<template>
    <b-modal
        id="create-retencion-modal"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Crear tercero"
        @shown="openModal"
        @close="resetFom"
    >
        <b-card class="mb-4 w-100">
            <div>
                <b-form class="p-5">
                    <div class="d-flex">
                        <div>
                            <h3 class="mb-2">Datos de la retefuente</h3>
                            <div class="text-gray mb-5">
                                Por favor registre los datos de la retefuente
                                para continuar
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols="12" md="3" lg="3" xl="3">
                            <b-form-group>
                                <label for="codigo">Código</label>
                                <b-form-input
                                    id="codigo"
                                    type="text"
                                    placeholder="Ej: 001"
                                    v-model="$v.retencion.codigo.$model"
                                    :state="validateState('codigo')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.retencion.codigo.$anyError"
                                >
                                    ({{ $v.retencion.codigo.$model.length }}
                                    /
                                    {{
                                        $v.retencion.codigo.$params.maxLength
                                            .max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.retencion.codigo.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.retencion.codigo.$params.maxLength
                                            .max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.retencion.codigo.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="9" lg="9" xl="9">
                            <b-form-group>
                                <label for="descripcion">Descripción</label>
                                <b-form-input
                                    id="descripcion"
                                    type="text"
                                    placeholder="Ej: Mi configuración de retención"
                                    v-model="$v.retencion.descripcion.$model"
                                    :state="validateState('descripcion')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.retencion.descripcion.$anyError"
                                >
                                    ({{
                                        $v.retencion.descripcion.$model.length
                                    }}
                                    /
                                    {{
                                        $v.retencion.descripcion.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.retencion.descripcion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.retencion.descripcion.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.retencion.descripcion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="porcentaje_declarante"
                                    >Porcentaje declarante</label
                                >
                                <b-form-input
                                    id="porcentaje_declarante"
                                    type="number"
                                    placeholder="Ej: 19"
                                    v-model="
                                        $v.retencion.porcentaje_declarante
                                            .$model
                                    "
                                    :state="
                                        validateState('porcentaje_declarante')
                                    "
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="
                                        !$v.retencion.porcentaje_declarante
                                            .$anyError
                                    "
                                >
                                    ({{
                                        $v.retencion.porcentaje_declarante
                                            .$model.length
                                    }}
                                    /
                                    {{
                                        $v.retencion.porcentaje_declarante
                                            .$params.maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.retencion.porcentaje_declarante
                                            .maxLength
                                    "
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.retencion.porcentaje_declarante
                                            .$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.retencion.porcentaje_declarante
                                            .required
                                    "
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="porcentaje_no_declarante"
                                    >Porcentaje no declarante</label
                                >
                                <b-form-input
                                    id="porcentaje_no_declarante"
                                    type="number"
                                    placeholder="Ej: 19"
                                    v-model="
                                        $v.retencion.porcentaje_no_declarante
                                            .$model
                                    "
                                    :state="
                                        validateState(
                                            'porcentaje_no_declarante'
                                        )
                                    "
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="
                                        !$v.retencion.porcentaje_no_declarante
                                            .$anyError
                                    "
                                >
                                    ({{
                                        $v.retencion.porcentaje_no_declarante
                                            .$model.length
                                    }}
                                    /
                                    {{
                                        $v.retencion.porcentaje_no_declarante
                                            .$params.maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.retencion.porcentaje_no_declarante
                                            .maxLength
                                    "
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.retencion.porcentaje_no_declarante
                                            .$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.retencion.porcentaje_no_declarante
                                            .required
                                    "
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="porcentaje_no_declarante"
                                    >Porcentaje reteiva</label
                                >
                                <b-form-input
                                    id="porcentaje_reteiva"
                                    type="number"
                                    placeholder="Ej: 19"
                                    v-model="
                                        $v.retencion.porcentaje_reteiva.$model
                                    "
                                    :state="validateState('porcentaje_reteiva')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="
                                        !$v.retencion.porcentaje_reteiva
                                            .$anyError
                                    "
                                >
                                    ({{
                                        $v.retencion.porcentaje_reteiva.$model
                                            ? $v.retencion.porcentaje_reteiva.$model.toString()
                                                  .length
                                            : '0'.length
                                    }}
                                    /
                                    {{
                                        $v.retencion.porcentaje_reteiva.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.retencion.porcentaje_reteiva
                                            .maxLength
                                    "
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.retencion.porcentaje_reteiva.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="
                                        !$v.retencion.porcentaje_no_declarante
                                            .required
                                    "
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <label for="base_retencion">Base retención</label>
                            <currencyInput
                                id="base_retencion"
                                v-model="$v.retencion.base_retencion.$model"
                                :state="validateState('base_retencion')"
                                :disabled="read_only"
                            />
                            <!-- <pre>{{ $v.retencion | pretty }}</pre> -->
                            <div
                                class="length-field mt-1"
                                v-if="!$v.retencion.base_retencion.$anyError"
                            >
                                ({{
                                    $v.retencion.base_retencion.$model.toString()
                                        .length
                                }}
                                /
                                {{
                                    $v.retencion.base_retencion.$params
                                        .maxLength.max
                                }})
                            </div>
                            <div
                                class="invalid-field mt-1"
                                v-if="!$v.retencion.base_retencion.maxLength"
                            >
                                Este campo debe tener máximo
                                {{
                                    $v.retencion.base_retencion.$params
                                        .maxLength.max
                                }}
                                caracteres
                            </div>
                            <div
                                class="invalid-field mt-1"
                                v-if="!$v.retencion.base_retencion.required"
                            >
                                Campo requerido
                            </div>
                            <!-- @focus-c="registerIndex = i"
                  @input="registerIndex = i" -->
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="cuenta_venta"
                                    >Cuenta de ventas
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="cuenta_venta"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoCuenta = 'cuenta_venta'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="$v.retencion.cuenta_venta.$model"
                                    :state="validateState('cuenta_venta')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="item.tipo === 'S'"
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.retencion.cuenta_venta.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="6" xl="6">
                            <b-form-group>
                                <label for="cuenta_compra"
                                    >Cuenta de compras
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="cuenta_compra"
                                    :items="puc"
                                    item-text="descripcion"
                                    item-value="id"
                                    return-object
                                    open-on-focus
                                    load-async
                                    no-null
                                    @text="filterPucData"
                                    :disabled="read_only"
                                    v-model="$v.retencion.cuenta_compra.$model"
                                    show-create-button
                                    :create-method="
                                        () => {
                                            tipoCuenta = 'cuenta_compra'
                                            $bvModal.show('create-puc-modal')
                                        }
                                    "
                                    :state="validateState('cuenta_compra')"
                                    :custom-filter="filterPuc"
                                >
                                    <template v-slot:option="{ item }">
                                        <option
                                            :class="`text-${
                                                item.tipo === 'S'
                                                    ? 'secondary'
                                                    : 'gray'
                                            }`"
                                            :disabled="item.tipo === 'S'"
                                        >
                                            {{ item.codigo }} -
                                            {{ item.descripcion }}
                                        </option>
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.retencion.cuenta_compra.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <div class="float-right">
                                <div class="d-flex flex-row">
                                    <b-button
                                        variant="primary"
                                        :disabled="isLoading || read_only"
                                        size="md"
                                        class="d-flex align-items-center"
                                        v-b-popover.hover.top="
                                            'Presione ctrl+shift+s o cmd+shift+s'
                                        "
                                        @click="save"
                                    >
                                        <feather
                                            type="save"
                                            size="1rem"
                                            class="mr-2 text-blue-active"
                                        />
                                        <span
                                            v-if="isLoading"
                                            class="d-sm-flex align-items-center justify-content-center"
                                        >
                                            <b-spinner small></b-spinner>
                                            <span class="ml-2"
                                                >Guardando...</span
                                            >
                                        </span>
                                        <span v-else>Guardar</span>
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-card>
        <crearPucModalVue @newPuc="setNewPuc" />
    </b-modal>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import currencyInput from '@/components/shared/currency-input/currency-input'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'
import { filterPuc } from '@/utils/filters'
import crearPucModalVue from '../../../contabilidad/configuracion/puc/crearPucModal.vue'

const { API } = services

const defaultRetencion = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            porcentaje_no_declarante: '',
            porcentaje_declarante: '',
            base_retencion: 0,
            porcentaje_reteiva: 0,
            cuenta_venta: null,
            cuenta_compra: null,
            empresa_id: null
        })
    )

export default {
    name: 'CrearRetencion',
    mixins: [validationMixin],
    components: { jautocomplete, currencyInput, crearPucModalVue },
    data: () => ({
        puc: [],
        retencionCopy: defaultRetencion(),
        retencion: defaultRetencion(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        tipoCuenta: ''
    }),
    watch: {
        retencion: {
            handler(newValue) {
                this.isSaved = compareObjects(this.retencionCopy, newValue)
            },
            deep: true
        }
    },
    async mounted() {
        addListenerCommands(this)
        await this.getParams()
        // this.getPuc()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        retencion: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            porcentaje_no_declarante: {
                required,
                maxLength: maxLength(5)
            },
            porcentaje_declarante: {
                required,
                maxLength: maxLength(5)
            },
            cuenta_venta: {
                required
            },
            cuenta_compra: {
                required
            },
            base_retencion: {
                required,
                maxLength: maxLength(10)
            },
            porcentaje_reteiva: {
                required,
                maxLength: maxLength(5)
            }
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        setNewPuc(pucItem) {
            this.puc.push(pucItem)
            this.retencion[this.tipoCuenta] = pucItem
        },
        filterPuc,
        async getParams() {
            const { retencion_id } = this.$route.params
            if (retencion_id) {
                await this.getRetencionById(retencion_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        async getRetencionById(id) {
            // console.log('acá 2');
            await API.POST({
                url: 'administracion/inventario/retefuente/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then(async (response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        await this.getPuc({
                            term: dato.cuenta_compra.descripcion
                        })
                        await this.getPuc({
                            term: dato.cuenta_venta.descripcion
                        })
                        // console.log(dato);
                        this.retencionCopy = { ...dato }
                        this.retencion = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        filterPucData(text) {
            if (text && text.length > 3) {
                this.getPuc({ term: text })
            }
        },
        async getPuc({ term }) {
            await API.POST({
                url: 'contabilidad/puc/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.puc = dato.content
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.retencion.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.retencion.id ? 'UPDATE' : 'INSERT'
                this.retencion.empresa_id = this.getUltimaEmpresa.id
                API.POST({
                    url: 'administracion/inventario/retefuente/crud',
                    data: {
                        p_datajson: {
                            ...this.retencion
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            // this.back()
                            this.$emit('newRetencion', dato)
                            this.resetFom()
                            this.close()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.retencion.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.retencion[key]
            return $dirty ? !$error : null
        },
        openModal() {
            this.goToNextField('codigo')
        },
        resetFom() {
            this.retencion = defaultRetencion()
            this.$v.retencion.$reset()
        },
        close() {
            this.$bvModal.hide('create-retencion-modal')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 13 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
